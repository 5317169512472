import { useState, useEffect, useRef } from "react";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  useMicrophoneAudioTrack,
  channelName,
} from "./settings.js";
import { Container, Row, Col } from "react-bootstrap";
import Video from "./Video";
import Controls from "./Controls";

export default function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [channelName, setChannelName] = useState("");
  const client = useClient();
  const { ready, track, error } = useMicrophoneAudioTrack();
  console.log(track);

  if (error && error.code === "PERMISSION_DENIED") {
    alert("please allow microphone access to make call");
  }
  //  else if (error && error.code === "CAN_NOT_GET_GATEWAY_SERVER") {
  //   window.location.reload();
  // }
  // else if (error && error.code === "NOT_READABLE") {
  //   alert("please check the camera not using in another program");
  // }

  const childRef = useRef();
  const childFunc = useRef(null);

  useEffect(() => {
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        // console.log(user);
        // if (mediaType === "video") {
        //   setUsers((prevUsers) => {
        //     return [...prevUsers, user];
        //   });
        // }
        if (mediaType === "audio") {
          user.audioTrack.play();
          // setUsers((prevUsers) => {
          //   return [...prevUsers, user];
          // });
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        // if (mediaType === "video") {
        //   if (user.videoTrack) user.videoTrack.stop();
        //   setUsers((prevUsers) => {
        //     return prevUsers.filter((User) => User.uid !== user.uid);
        //   });
        // }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      try {
        await client.join(
          config.appId,
          name,
          localStorage.getItem("room_token"),
          null
        );
      } catch (error) {
        if (
          error.code == "INVALID_PARAMS" ||
          error.code === "CAN_NOT_GET_GATEWAY_SERVER"
        ) {
          window.location.reload();
        } else {
          alert(error);
        }
      }

      if (track) await client.publish([track]);
      setStart(true);
    };

    if (ready && track) {
      try {
        init(channelName);
      } catch (error) {
        console.log(error);
      }
    }
  }, [channelName, client, ready, track, localStorage.getItem("room_token")]);

  useEffect(() => {
    setChannelName(localStorage.getItem("room_name"));
    const myIntrval = setInterval(() => {
      try {
        childFunc.current();
      } catch (error) {
        console.log(error);
      }
    }, 10000);
    return () => {
      clearInterval(myIntrval);
      try {
        childRef.current();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <Container className="incall text-center h-100">
      <Row className="h-100">
        <Col>
          {/* {ready && track && (
            <Controls
              track={track}
              setStart={setStart}
              setInCall={setInCall}
              childRef={childRef}
            />
          )} */}
          {ready && start && track && (
            <Video
              track={track}
              setStart={setStart}
              setInCall={setInCall}
              childRef={childRef}
              childFunc={childFunc}
              users={users}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
