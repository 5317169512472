import { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import API from "../../utils/DevApi";
import reject from "../helpers/images/reject.png";
import ringtone from "../helpers/audio/MakingCall.mp3";
import AuthContext from "../../store/auth-context";
import "./Calling.scss";

const Calling = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [roomId, setRoomId] = useState(null);
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const audioElement = new Audio(ringtone);
  let userImg = user
    ? user.image.length
      ? user.image
      : defaultImage
    : defaultImage;

  async function fetchUser() {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        id: id,
      };
      const response = await API.post("/show/user", bodyParameters, config);
      setUser(response.data.data.user);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  const endCall = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const bodyParameters = {
        id: id,
        status: 2,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await API.post("update/call", bodyParameters, config);
      localStorage.removeItem("calling_user_id");
      localStorage.removeItem("room_id");
      localStorage.removeItem("room_name");
      localStorage.removeItem("room_token");
      localStorage.removeItem("calling_name");
      localStorage.removeItem("calling_image");
      history.replace("/");
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser(id);
    setRoomId(localStorage.getItem("roomId"));
    audioElement.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioElement.play();
    return () => {
      audioElement.pause();
    };
  }, [id]);

  useEffect(() => {
    localStorage.setItem("calling_name", user.name);
    localStorage.setItem("calling_image", userImg);
  }, [user]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      toast.success(
        <div>
          <h4>Call Alert</h4>
          <p>User did not answer your call, please try again later.</p>
        </div>
      );
      endCall(roomId);
    }, 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, [roomId]);

  return (
    <Container className="calling text-center h-100">
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      <Row className="h-100">
        <Col>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div
                className="bg-image"
                style={{
                  backgroundImage: `url(${userImg})`,
                }}
              ></div>
              <div className="h-100 d-flex flex-column justify-content-center text-overlay">
                <span className="mt-0 mb-5 text">Calling...</span>
                <div className="call-animation">
                  <img
                    className="img-circle"
                    src={userImg}
                    alt=""
                    width="135"
                  />
                </div>
                <h4 className="my-5 text">{user.name}</h4>
                <div className="answerButtons">
                  <img
                    src={reject}
                    className="img-fluid"
                    onClick={() => endCall(roomId)}
                  />
                </div>
              </div>
              {/* <audio loop autoPlay className="audio-element" ref={audioEl}>
                <source src={ringtone}></source>
              </audio> */}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Calling;
