import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Button, Modal } from "react-bootstrap";
import API from "../../utils/API";
import bell from "../helpers/images/bell.png";
import "./NotfiyMe.scss";

const NotfiyMe = ({ show, handleClose, user }) => {
  // console.log(user);
  // const [user, setUser] = setUser("");

  // useEffect(() => {
  //   console.log(user);
  //   user && setTitleId(user);
  // }, [user]);
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const notifyMy = async (id) => {
    if (show) {
      const token = localStorage.getItem("token");

      try {
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const bodyParameters = {
          user_id: id,
        };
        const response = await API.post("/notify/me", bodyParameters, config);
      } catch (error) {
        if (error.response.status == "401") {
          localStorage.clear();
          authCtx.logout();
          window.location.reload();
          history.replace("/");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    notifyMy(user.id);
  }, [show]);

  return (
    <Modal className="NotfiyMe" show={show} onHide={handleClose}>
      <Modal.Body className="text-center">
        <img src={bell} className="img-fluid mb-2 mb-md-3" alt="bell" />
        <h4 className="mb-2 mb-md-3">
          We will notify you when {user.name} is available to natter
        </h4>
        {/* <small>{user.name} will get back to you as soon as possible</small> */}
        <Button
          className="px-5"
          variant="primary"
          onClick={() => handleClose()}
        >
          continue
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default NotfiyMe;
