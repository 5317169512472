import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDr-kYsp_NZhWG2yKM4zOxf7Souwve0tQw",
  authDomain: "natter-search.firebaseapp.com",
  projectId: "natter-search",
  storageBucket: "natter-search.appspot.com",
  messagingSenderId: "729548705375",
  appId: "1:729548705375:web:3dc80f99307c7f31ac15ea",
  measurementId: "G-KJ6ESZ1CNT",
};

firebase.initializeApp(firebaseConfig);

if (firebase.messaging.isSupported()) {
  var messaging = firebase.messaging();
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
