import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import sidemenu from "../helpers/images/sidemenu.png";
import sidemenuleft from "../helpers/images/sidemenuleft.png";

// import Search from "../Search/Search";
// import UserData from "../UserData/UserData";
import "./Home.scss";
const Home = (props) => {
  const [toggle, setToggle] = useState(false);
  let IS_ANDROID = navigator.userAgent.match(/Android/i) != null;
  let marginValue = IS_ANDROID ? "-12.25rem" : "";

  return (
    <Container className={`home ${toggle ? "sb-sidenav-toggled" : ""}`}>
      <Row id="wrapper">
        <Col
          xs={6}
          md={3}
          lg={2}
          id="sidebar-wrapper"
          style={{ marginLeft: marginValue }}
        >
          <Sidebar />
        </Col>

        <Col md={9} lg={10} id="page-content-wrapper">
          <img
            src={toggle ? sidemenuleft : sidemenu}
            className="d-block d-md-none"
            alt="side menu icon"
            style={{
              position: "absolute",
              top: "0",
              left: "15px",
              zIndex: "999",
              width: "30px",
            }}
            onClick={() => setToggle(!toggle)}
          />
          {props.children}
        </Col>
      </Row>
      {/* <div class="d-flex" id="wrapper">
        <div id="sidebar-wrapper">
          <Sidebar />
        </div>
        <div id="page-content-wrapper">{props.children}</div>
      </div> */}
    </Container>
  );
};

export default Home;
