import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import API from "../../utils/API";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import "./DemoUser.scss";
const DemoUser = () => {
  const [demoUsers, setDemoUsers] = useState({
    users: ["demo@user1.com", "demo@user2.com", "demo@user3.com"],
    password: "nattersearch",
  });
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const handleClick = async () => {
    setLoading(true);
    var deviceToken;
    if (localStorage.getItem("deviceToken")) {
      deviceToken = localStorage.getItem("deviceToken");
    } else {
      deviceToken = "Null";
    }
    const data = {
      username:
        demoUsers.users[Math.floor(Math.random() * demoUsers.users.length)],
      password: demoUsers.password,
      device_token: deviceToken,
    };
    try {
      const response = await API.post("/user/login", data);
      let expirationTime = new Date(new Date().getTime() + 3600 * 1000);
      authCtx.login(
        response.data.data.user.token,
        response.data.data.user,
        expirationTime
      );
      window.location.reload();
      //   history.replace("/");
    } catch (error) {
      setLoading(false);
      //   error.response.data.message;
    }
  };

  return loading ? (
    <Loader />
  ) : (<></>
    // <Button className="demouser my-2" onClick={handleClick}>
    //   Demo Mode
    // </Button>
  );
};

export default DemoUser;
