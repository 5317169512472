import React, { useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import "./AddExpert.scss";

const AddExpert = ({ show, handleClose, fetchUser }) => {
  const [display, setDisplay] = useState("none");
  const [title, setTitle] = useState("");
  const [titleid, setTitleId] = useState(null);
  const [years, setYears] = useState("");
  const [description, setDescription] = useState("");
  const [errorDes, setErrorDes] = useState(null);
  const [topics, setTopics] = useState([]);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorExp, setErrorExp] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const handleTitle = async (query) => {
    const trimQuery = query.trim();
    setTitle(query);
    setTitleId(null);
    if (trimQuery.length === 0) {
      setTopics([]);
      setErrorTitle(null);
      setErrorExp(null);
    } else if (trimQuery.length < 3 && trimQuery.length > 0) {
      setTopics([]);
      setErrorTitle("The must be at least 3 characters.");
    } else {
      setLoading(true);
      setErrorTitle(null);
      setErrorExp(null);
      try {
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const bodyParameters = {
          name: trimQuery,
        };
        const response = await API.post(
          "/topic/search",
          bodyParameters,
          config
        );
        setLoading(false);
        setTopics(response.data.data.topics);
        setErrorTitle(null);
        setErrorExp(null);
        setDisplay("block");
      } catch (errorTitle) {
        if (errorTitle.response.status == "401") {
          localStorage.clear();
          authCtx.logout();
          window.location.reload();
          history.replace("/");
        }
        const { name } = errorTitle.response.data.errorTitles;
        setLoading(false);
        setTopics(null);
        setErrorTitle(name);
        setDisplay("none");
      }
    }
  };
  const handleClick = (id, title) => {
    setTitle(title);
    setTitleId(id);
    setTopics([]);
    setErrorTitle(null);
    setDisplay("none");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      years_exp: years,
      description: description,
    };
    titleid !== null
      ? (data.topic_id = titleid)
      : (data.topic_title = title.trim());
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await API.post("/user/add/expert", data, config);
      setErrorTitle(null);
      setErrorExp(null);
      fetchUser();
      setTitle("");
      setYears("");
      setDescription("");
      handleClose();
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { topic_title, years_exp, description } =
        error.response.data.errors;
      topic_title && setErrorTitle("The topic title field is required");
      setErrorExp(years_exp);
      setErrorDes(description);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="topic_title">
            <Form.Label>
              Topic:{" "}
              {/* <span className="text-danger">
                &nbsp;<sup>*</sup>
              </span> */}
            </Form.Label>
            <Form.Control
              type="text"
              value={title}
              autoComplete="off"
              onChange={(e) => handleTitle(e.target.value)}
            />
            {errorTitle && <div className="text-danger">{errorTitle}</div>}
            {loading ? (
              <Loader />
            ) : (
              <div className="suggesstion-box" style={{ display: display }}>
                {topics.length > 0 ? (
                  <ul className="suggesstion-list">
                    {topics.map((topic) => (
                      <li
                        key={topic.id}
                        onClick={() => handleClick(topic.id, topic.title)}
                      >
                        {topic.title}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {/* No match found add this topic */}
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="years_exp">
            <Form.Label>
              Years of experience:{" "}
              {/* <span className="text-danger">
                &nbsp;<sup>*</sup>
              </span> */}
            </Form.Label>
            <Form.Control
              type="text"
              value={years}
              autoComplete="off"
              onChange={(e) => setYears(e.target.value)}
            />
            {errorExp && <div className="text-danger">{errorExp}</div>}
          </Form.Group>
          <Form.Group className="mb-0" controlId="description">
            <Form.Label>
              Description:{" "}
              {/* <span className="text-danger">
                &nbsp;<sup>*</sup>
              </span> */}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errorDes && <div className="text-danger">{errorDes}</div>}
          </Form.Group>
          {/* <small className="text-danger">
            Note (*) this mean is input required
          </small> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`px-5 ${
              title.trim().length > 2 &&
              years.trim().length > 0 &&
              description.trim().length > 2
                ? ""
                : "disabled"
            }`}
            variant="primary"
            type="submit"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddExpert;
