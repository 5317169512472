import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import API from "../../utils/API";
import "./SendMessage.scss";

const SendMessage = ({ show, handleClose, user }) => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [response, setResponse] = useState("");

  const send = async (id) => {
    const token = localStorage.getItem("token");

    if (message.length > 100) {
      setMessageError("Max length of the message is 100 character");
      return 0;
    } else {
      setMessageError(null);
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        receiver: id,
        message,
      };
      const response = await API.post("/send/message", bodyParameters, config);
      setResponse(response.data.message);
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      setMessageError(error.response.error);
      console.log(error);
    }
  };

  return (
    <Modal className="SendMessage" show={show} onHide={handleClose}>
      <Modal.Body className="text-center">
        <h4 className="mb-2 mb-md-3">Send your message to {user.name}</h4>
        {response && <Alert variant="success">{response}</Alert>}
        <Form.Group controlId="Message">
          <Form.Control
            as="textarea"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength="256"
          />
          {messageError && <div className="text-danger">{messageError}</div>}
          <small className="d-block text-right mt-2">
            {message.length} / 256
          </small>
        </Form.Group>
        <Button
          className={`px-5 ${message.trim().length > 2 ? "" : "disabled"}`}
          variant="primary"
          onClick={() => send(user.id)}
        >
          Send
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SendMessage;
