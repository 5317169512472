import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Button, Modal, Form } from "react-bootstrap";
import API from "../../utils/API";
import "./EditExpert.scss";

const EditExpert = ({ show, handleClose, fetchUser, expert }) => {
  const [titleid, setTitleId] = useState(null);
  const [title, setTitle] = useState("");
  const [years, setYears] = useState("");
  const [des, setDescription] = useState("");
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    // const [id, years_exp, description, topic_title] = expert;
    expert && setTitleId(expert.id);
    expert && setTitle(expert.topic_title);
    expert && setYears("" + expert.years_exp);
    expert && setDescription(expert.description);
  }, [expert]);

  const [errorExp, setErrorExp] = useState(null);
  const [errorDes, setErrorDes] = useState(null);

  const token = localStorage.getItem("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      id: titleid,
      years_exp: years,
      description: des,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await API.post("/update/expert", data, config);
      setErrorExp(null);
      setErrorExp("");
      setErrorDes("");
      fetchUser();
      handleClose();
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { years_exp, description } = error.response.data.errors;
      setErrorExp(years_exp);
      description && setErrorDes("The description field is required.");
    }
  };

  return (
    <Modal
      className="EditExpert"
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="topic_title">
            <Form.Label>Topic:</Form.Label>
            <Form.Control
              type="text"
              value={title}
              autoComplete="off"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="years_exp">
            <Form.Label>
              Years of experience:{" "}
              {/* <span className="text-danger">
                &nbsp;<sup>*</sup>
              </span> */}
            </Form.Label>
            <Form.Control
              type="text"
              value={years}
              onChange={(e) => setYears(e.target.value)}
              autoComplete="off"
            />
            {errorExp && <div className="text-danger">{errorExp}</div>}
          </Form.Group>
          <Form.Group className="mb-0" controlId="description">
            <Form.Label>
              Description:{" "}
              {/* <span className="text-danger">
                &nbsp;<sup>*</sup>
              </span> */}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={des}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errorDes && <div className="text-danger">{errorDes}</div>}
          </Form.Group>
          {/* <small className="text-danger">
            Note (*) this mean is input required
          </small> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`px-5 ${
              title.trim().length > 2 &&
              years.trim().length > 0 &&
              des.trim().length > 2
                ? ""
                : "disabled"
            }`}
            variant="primary"
            type="submit"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditExpert;
