import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HzHomeImage from "../helpers/images/hzhomeimage3.jpg";
import vHomeImage from "../helpers/images/vhomeimage3.jpg";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";
import "./Aboutus.scss";

const Aboutus = () => {
  // const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  // const paddingValue = iOS ? "17vh" : "";
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;
  return (
    <div
      className={`aboutUs ${aboutus ? "py-0" : ""}
        `}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="greenBackGround">
            <h2 className="py-2 mb-0 white ">What is NatterSearch?</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <br/>
            <p>
              Want to earn money by simply sharing your knowledge with others?
              Every minute you spend on a voice call talking about what you know
              best will add $$$ to your wallet.{" "}
              <b>Be the expert others will natter with.</b>
            </p>
            <p>
              Your mind holds unique experiences and knowledge others will not
              find when they search the internet. Knowledge from a degree you
              earned, a career path, a destination you visited, or even a hobby
              you enjoy.
            </p>
            <p>
              <b>
                It’s knowledge – Unique to YOU! And others are willing to pay
                for.
              </b>
            </p>
            <p>
              Have you searched the internet, but still need to hear from an
              expert with first-hand experience on a specific question or topic?
              You’ve come to the right place.
              <b>Connect with an expert and natter away.</b>
            </p>
            <p>
              Search our database of topics, see reviews and ratings to help you
              choose an expert on that topic, and get answers to all your
              questions in an in-app voice call with an expert.
            </p>
            <p>
              Whether you want to <b>share</b> or <b>seek knowledge</b>,
              NatterSearch is your Go-To Platform.
            </p>
            <p>
              Sign up in seconds for Knowledge at Your Fingertips – When you
              Need it.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} className="greenBackGround">
            <h2 className="py-2 mb-0 white ">How to Share your Knowledge</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <p>
              <br></br>
              <ul className="green">
                <li>Sign up in seconds using an email or phone number.</li>
                <li>
                  List your topics of expertise (up to 10 topics) and brag about
                  your years of knowledge and first-hand experiences.
                </li>
                <li>
                  Name your price in $/minute and set your status to “Available
                  to Natter.”
                </li>
                <li>
                  Users will find you in our database when they search a topic
                  you are knowledgeable in.
                </li>
                <li>
                  Receive in-app calls when you are available and share your
                  knowledge.
                </li>
                <li>
                  At the end of the call, you will receive 80% of the call
                  price.
                </li>
                <li>Users will rate your expertise after each voice call.</li>
                <li>
                  Accumulate $$$ and transfer to any PayPal account in seconds
                  for FREE or use your $$$ to call experts to learn about your
                  topic of interest.
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} className="greenBackGround">
            <h2 className="py-2 mb-0 white ">How to Seek Experts’ Knowledge</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <p>
              <br></br>
              <ul className="green">
                <li>Type a keyword in our search engine.</li>
                <li>
                  A list of experts will be sorted based on rating, availability
                  to natter, and price.
                </li>
                <li>
                  See reviews and ratings to help you choose an expert on that
                  topic.
                </li>
                <li>
                  Ensure your wallet has enough funds to initiate a call
                  (Minimum - $$$ to complete a 15-minute call).
                </li>
                <li>
                  Initiate an in-app voice call and ask your expert all the
                  questions you can think of.
                </li>
                <li>
                  At the end of the call, you will have the opportunity to rate
                  the expert and write a review (optional).
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
