import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import useGeoLocation from "../hooks/useGeoLocation";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/DevApi";
import group5126 from "../helpers/images/group5126.png";
import "./EditProfile.scss";

const EditProfile = () => {
  // const { id } = useParams();
  // const LocalUser = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);

  const [demo, setDemo] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(undefined);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(undefined);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(undefined);
  const [callPrice, setCallPrice] = useState("");
  const [callPriceError, setCallPriceError] = useState(undefined);
  const [age, setAge] = useState("");
  const [ageError, setAgeError] = useState(undefined);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(undefined);
  const [location, setLocation] = useState("");
  const [response, setResponse] = useState("");
  const coordinates = useGeoLocation();
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  async function fetchUser() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/user/profile", config);
      setUser(response.data.data.user);
      setName(response.data.data.user.name);
      setEmail(response.data.data.user.email);
      if (
        response.data.data.user.email.includes("demo@user1.com") ||
        response.data.data.user.email.includes("demo@user2.com") ||
        response.data.data.user.email.includes("demo@user3.com")
      ) {
        setDemo(true);
      }
      setPhone(response.data.data.user.phone_number);
      setCallPrice(response.data.data.user.call_price);
      // setAge(response.data.data.user.age);
      setLocation(response.data.data.user.location);
      // if (response.data.data.user.gender === "Male") {
      //   setGender("0");
      // } else if (response.data.data.user.gender === "Female") {
      //   setGender("1");
      // } else {
      //   setGender("2");
      // }
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  const getLocation = async (latitude, longitude) => {
    if (latitude.length !== 0 && longitude.length !== 0) {
      try {
        const response = await axios.get(
          `https://us1.locationiq.com/v1/reverse.php?key=pk.9ddc8d900420336eb5d33f437cf1bd42&lat=${latitude}&lon=${longitude}&format=json`
        );

        if (response.data.address.country === "United States of America") {
          setLocation(
            `${
              response.data.address.city ? response.data.address.city + "," : ""
            } ${response.data.address.state}`
          );
        } else {
          setLocation(
            `${
              response.data.address.city ? response.data.address.city + "," : ""
            } ${response.data.address.country}`
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(async () => {
    coordinates.loaded &&
      location.length === 0 &&
      (await getLocation(
        coordinates.coordinates.lat,
        coordinates.coordinates.lng
      ));
  }, [coordinates, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (demo) {
      setResponse("Profile Updated");
      setTimeout(() => {
        history.replace("/myprofile");
      }, 2000);
      return 0;
    }
    setLoading(true);
    setResponse("");
    let data;
    let numericalAge = parseInt(age);
    var deviceToken;
    if (localStorage.getItem("deviceToken")) {
      deviceToken = localStorage.getItem("deviceToken");
    } else {
      deviceToken = "Null";
    }
    if (phone.length === 0) {
      data = {
        name,
        email,
        // age: numericalAge,
        // gender,
        location,
        call_price: callPrice,
        device_token: deviceToken,
      };
    } else if (email.length === 0) {
      data = {
        name,
        // age: numericalAge,
        // gender,
        phone_number: phone,
        location,
        call_price: callPrice,
        device_token: deviceToken,
      };
    } else if (email.length !== 0 && phone.length !== 0) {
      data = {
        name,
        email,
        // age: numericalAge,
        // gender,
        phone_number: phone,
        location,
        call_price: callPrice,
        device_token: deviceToken,
      };
    } else {
      setEmailError(
        "The email field is required when phone number is not present."
      );
      setPhoneError(
        "The phone number field is required when email is not present."
      );
      return 0;
    }
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.post("/user/profile/update", data, config);
      setNameError(undefined);
      setEmailError(undefined);
      setPhoneError(undefined);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      setResponse(response.data.message);
      setLoading(false);
      setTimeout(() => {
        history.replace("/myprofile");
      }, 2000);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { name, email, phone_number, age, gender } =
        error.response.data.errors;
      setNameError(name);
      setEmailError(email);
      setPhoneError(phone_number);
      setAgeError(age);
      setGenderError(gender);
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="justify-content-center editprofile">
        <Col xs={12} md={10}>
          <h4 className="my-2 my-md-4">Edit profile</h4>
          {response && <Alert variant="success">{response}</Alert>}
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="lightBg">
                <Row className="justify-content-center">
                  <Form.Group className="col-lg-11">
                    <div className="sn-field">
                      <Form.Control
                        size="lg"
                        type="text"
                        name="name"
                        placeholder="Full name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {nameError &&
                        nameError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group>
                  {/* pattern="\d*" minLength="2" maxLength="3" */}
                  {/* <Form.Group className="col-lg-11">
                    <div className="sn-field">
                      <select
                        className="form-control"
                        onChange={(e) => setGender(e.target.value)}
                        defaultValue={gender}
                      >
                        <option
                          value="DEFAULT"
                          disabled
                          style={{ color: "#caeee6" }}
                        >
                          Select gender
                        </option>
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                        <option value="2">Unspecified</option>
                      </select>
                      {genderError &&
                        genderError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-lg-11">
                    <div className="sn-field">
                      <Form.Control
                        size="lg"
                        type="text"
                        name="age"
                        placeholder="Age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                      />
                      {ageError &&
                        ageError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group> */}

                  <Form.Group className="col-lg-11">
                    <div className="sn-field">
                      <Form.Control
                        size="lg"
                        type="text"
                        name="phone_number"
                        placeholder="Phone number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {phoneError &&
                        phoneError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-lg-11">
                    <div className="sn-field">
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError &&
                        emailError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-lg-3">
                    <div className="sn-field d-flex align-items-center">
                      $&nbsp;
                      <Form.Control
                        size="lg"
                        type="text"
                        name="call price"
                        placeholder="Call price"
                        value={callPrice}
                        onChange={(e) => setCallPrice(e.target.value)}
                      />
                      {callPriceError &&
                        callPriceError.map((error, index) => (
                          <small className="text-danger d-block" key={index}>
                            {error}
                          </small>
                        ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-8 d-flex flex-column justify-content-center pl-0">
                    <div>
                      {/* <img src={group5126} width="10%" /> */}
                      per minute
                    </div>
                  </Form.Group>
                </Row>
              </div>
              <Form.Group className="col-12 text-center ">
                <Button
                  size="lg"
                  type="submit"
                  className="px-5 d-inline-block mt-4"
                >
                  Submit
                </Button>
              </Form.Group>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EditProfile;
