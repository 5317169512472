import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import ReactStars from "react-stars";
import { GoLocation } from "react-icons/go";
import Loader from "../Loader/Loader";
import { Row, Col } from "react-bootstrap";
import API from "../../utils/API";
import defaultImage from "../helpers/images/default_image.png";
import Favourite from "../Favourite/Favourite";
import love from "../helpers/images/love.png";
import "./MyFavourite.scss";

const MyFavourite = () => {
  const [myFavourite, setMyFavourite] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const fetchFavourite = async (token) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/myfavourite", config);
      setMyFavourite(response.data.data.users);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFavourite(token);
  }, [token]);

  // const addRemoveFavourite = async (id, token) => {
  //   setLoading(true);
  //   setMyFavourite([]);
  //   try {
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` },
  //     };

  //     const bodyParameters = {
  //       favourite_to: id,
  //     };
  //     const response = await API.post(
  //       "/favourite/user",
  //       bodyParameters,
  //       config
  //     );
  //     fetchFavourite(token);
  //   } catch (error) {
  //     alert(error.data.message);
  //   }
  // };

  const renderFavourite = (myFavourite, token) => {
    const favouriteList = myFavourite.map((favourite) => {
      return (
        <Col xs={12} md={10} lg={8} className="profile-page" key={favourite.id}>
          <div className="favourite card profile-header">
            <div className="body">
              <Row>
                <Link
                  to={`/userprofile/${favourite.id}`}
                  className="d-flex col"
                >
                  <Col xs={3} md={2} lg={1} className="px-0 ">
                    <div className="profile-image text-center">
                      <img
                        src={
                          favourite.image.length > 0
                            ? favourite.image
                            : defaultImage
                        }
                        alt="heart icon"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-center">
                    <h5 className="m-0">
                      <strong>{favourite.name}</strong>
                    </h5>
                    {/* <span className="job_post">Ui UX Designer</span> */}
                    {favourite.location && (
                      <small className="location">
                        <GoLocation className="mb-2" /> {favourite.location}
                      </small>
                    )}
                    <ReactStars
                      count={5}
                      size={16}
                      isHalf={true}
                      edit={false}
                      value={parseFloat(favourite.AvgRate)}
                      color1="#ced4da"
                      color2="#3c9b83"
                    />
                  </Col>
                </Link>
                <Col
                  xs={2}
                  md={3}
                  lg={2}
                  className="text-center d-flex flex-column justify-content-center"
                >
                  <Favourite
                    favourite_to={favourite.id}
                    IsFavourite={favourite.IsFavourite}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      );
    });
    return favouriteList;
  };

  return (
    <Row className="justify-content-center">
      {loading && <Loader />}
      {myFavourite.length
        ? renderFavourite(myFavourite, token)
        : !loading && (
            <div className="col-12 text-center">
              <img src={love} />
              <h6>you don't have any favourite yet</h6>
            </div>
          )}
    </Row>
  );
};

export default MyFavourite;
