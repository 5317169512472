import React from "react";
import { Spinner } from "react-bootstrap";
function Loader() {
  return (
    <div className="text-center mt-4">
      <Spinner animation="border" variant="success">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loader;
