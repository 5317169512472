import {
  createClient,
  createMicrophoneAndCameraTracks,
  createMicrophoneAudioTrack,
} from "agora-rtc-react";

const appId = "5ee7425eb209418b80a4a3402724cac0";
const token = localStorage.getItem("room_token");

export const config = { mode: "rtc", codec: "vp8", appId: appId, token: token };
export const useClient = createClient(config);
// export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
export const useMicrophoneAudioTrack = createMicrophoneAudioTrack();
// export const channelName = localStorage.getItem("room_name");
