import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import API from "../../utils/DevApi";
import UserCard from "../userCard/UserCard";
import "./Search.scss";
import nscircle from "../helpers/images/component221.png";
// import searchIcon from "../helpers/images/group3.png";
import Loader from "../Loader/Loader";
import group3 from "../helpers/images/group3.png";

const Search = () => {
  // const [query, setQuery] = useState("");
  // const [users, setUsers] = useState([]);
  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const [display, setDisplay] = useState("none");
  const [title, setTitle] = useState("");
  const [titleid, setTitleId] = useState(null);
  const [users, setUsers] = useState([]);
  const [finshed, setFinshed] = useState(false);
  const [error, setError] = useState(null);
  const [topics, setTopics] = useState([]);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorExp, setErrorExp] = useState(null);

  const handleTitle = async (query) => {
    let searchQuery = query.trim();
    setFinshed(false);
    setTitle(query);
    setTitleId(null);
    if (searchQuery.length === 0) {
      setTopics([]);
      setErrorTitle(null);
      setErrorExp(null);
    } else if (searchQuery.length < 3 && searchQuery.length > 0) {
      setTopics([]);
      setErrorTitle("The must be at least 3 characters.");
    } else {
      setLoading(true);
      setErrorTitle(null);
      setErrorExp(null);
      try {
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const bodyParameters = {
          name: searchQuery,
        };
        const response = await API.post(
          "topic/home/search",
          bodyParameters,
          config
        );
        setLoading(false);
        if (response.data.data.topics.length > 0) {
          setTopics(response.data.data.topics);
          setDisplay("block");
        } else {
          setTopics(response.data.data.topics);
          setDisplay("none");
        }
        setErrorTitle(null);
        setErrorExp(null);
      } catch (errorTitle) {
        if (errorTitle.response.status == "401") {
          localStorage.clear();
          authCtx.logout();
          window.location.reload();
          history.replace("/");
        }
        // errorTitle.response.data.errors;
        const { name } = errorTitle.response.data.errorTitles;
        setLoading(false);
        setTopics(null);
        setErrorTitle(name);
        setDisplay("none");
      }
    }
  };
  const handleClick = (id, title) => {
    setTitle(title);
    setTitleId(id);
    setTopics([]);
    setErrorTitle(null);
    setDisplay("none");
    searchQuery(title, id, token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    searchQuery(title, titleid, token);
  };

  // const handleSearch = (e) => {
  //   setQuery(e.target.value);
  // };

  // useEffect(() => {
  //   if (query.length > 2) {
  //     setLoading(true);
  //     searchQuery(query, token);
  //     setError(null);
  //   } else if (query.length < 3 && query.length > 0) {
  //     setUsers([]);
  //     setError("The must be at least 3 characters.");
  //   } else {
  //     setUsers([]);
  //     setError("");
  //   }
  // }, [query, token]);

  const searchQuery = async (query, id, token) => {
    setLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let bodyParameters = {
        name: query,
      };
      // if (id !== null) {
      //   bodyParameters.id = id;
      // } else {
      //   bodyParameters.name = query;
      // }
      var response = await API.post("/search", bodyParameters, config);
      setLoading(false);
      setUsers(response.data.data.users);
      localStorage.setItem("searchQuery", query);
      localStorage.setItem(
        "usersResult",
        JSON.stringify(response.data.data.users)
      );
      setFinshed(true);
      setError("");
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      setLoading(false);
      const { name } = error.response.data.errors;
      setError(name[0]);
      // console.log(name[0]);
    }
  };

  useEffect(() => {
    var mySetTimeout;
    if (title.length === 0 && setUsers.length > 0) {
      setTitle("");
      setUsers([]);
      mySetTimeout = setTimeout(() => {
        localStorage.removeItem("searchQuery");
        localStorage.removeItem("usersResult");
      }, 500);
    }
    return () => {
      clearTimeout(mySetTimeout);
    };
  }, [title]);

  useEffect(() => {
    setTitle(localStorage.getItem("searchQuery") || "");
    setUsers(JSON.parse(localStorage.getItem("usersResult")) || []);
  }, []);

  // useEffect(() => {
  //   localStorage.removeItem("calling_user_id");
  //   localStorage.removeItem("calling_name");
  //   localStorage.removeItem("calling_image");
  //   localStorage.removeItem("room_id");
  //   localStorage.removeItem("room_name");
  //   localStorage.removeItem("room_token");
  //   localStorage.removeItem("calling_name");
  //   localStorage.removeItem("calling_image");
  // }, []);

  return (
    <form className="h-100" onSubmit={handleSubmit}>
      <Row className="search mb-2 mb-md-4">
        <Col lg={10} className="mx-auto">
          <Row className="justify-content-center mb-4">
            <Col xs={7} md={3} className="text-center">
              <img src={nscircle} className="img-fluid bigcircle" />
            </Col>
          </Row>
          <div className="input-group p-2">
            <div className="input-group-prepend">
              <img src={group3} className="img-fluid" />
            </div>
            <input
              type="search"
              placeholder="Searching for minds..."
              aria-describedby="button-addon3"
              className="form-control border-0 form-control-lg"
              value={title}
              onChange={(e) => {
                handleTitle(e.target.value);
              }}
            />
            <Button
              type="submit"
              size="lg"
              className={`${
                display === "none" && !loading && title.trim().length > 2
                  ? ""
                  : "disabled"
              }`}
            >
              Natter about
            </Button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div
              className="suggesstion-box"
              style={{
                display: display,
                position: "absolute",
                width: "100%",
              }}
            >
              {topics.length > 0 ? (
                <ul
                  className="suggesstion-list"
                  style={{
                    position: "relative",
                    margin: "0",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  {topics.map((topic) => (
                    <li
                      key={topic.id}
                      onClick={() => {
                        handleClick(topic.id, topic.title);
                      }}
                    >
                      {topic.title}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
              {/* No match found add this topic */}
            </div>
          )}
          {error && <span className="text-danger">{error}</span>}
          {users && users.map((user) => <UserCard key={user.id} user={user} />)}
          {finshed && users.length === 0 && (
            <p className="text-center mt-4">
              No experts for this topic. As we build our network, we'll notify
              you when the topic is added.
            </p>
          )}
        </Col>
      </Row>
    </form>
  );
};

export default Search;
