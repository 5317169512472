import React, { useState, useContext, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
import {
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineCalendar,
  AiFillGift,
} from "react-icons/ai";
import { FaTransgender, FaEye, FaEyeSlash } from "react-icons/fa";
import "./SingUpForm.scss";
import API from "../../utils/API";
import useGeoLocation from "../hooks/useGeoLocation";
import axios from "axios";
import AuthContext from "../../store/auth-context";

const SingUpForm = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(undefined);
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [password2, setPassword2] = useState("");
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordError, setPasswordError] = useState(undefined);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState([]);
  const [age, setAge] = useState("");
  const [ageError, setAgeError] = useState([]);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(undefined);
  const [referralCode, setReferralCode] = useState("");
  const [referralCodeError, setReferralCodeError] = useState(undefined);
  const [notRobotError, setNotRobotError] = useState(undefined);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [cityLocation, setcityLocation] = useState("");
  const location = useGeoLocation();
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const recaptchaRef = useRef(null);

  const style = { color: "#3c9b83" };

  useEffect(() => {
    getLocation(location.coordinates.lat, location.coordinates.lng);
  }, [location]);

  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();
    if (captchaToken.length === 0) {
      setNotRobotError("please verify you are not robot");
      return 0;
    } else {
      setNotRobotError(undefined);
    }
    // recaptchaRef.current.reset();

    // console.log(parseInt(age) < 18 || parseInt(age) > 130);
    // if (parseInt(age) < 18 || parseInt(age) > 130) {
    //   console.log("error");
    //   setAgeError(["Age should be at least 18"]);
    // } else if (!parseInt(age)) {
    //   setAgeError(["The age field is required."]);
    // } else {
    //   setAgeError([]);
    // }
    // if (phone.length !== 0 && phone.length < 7) {
    //   setPhoneError(["Phone number should be at least 7 number"]);
    // } else if (phone.length === 0 && email.length === 0) {
    //   setPhoneError([
    //     "The phone number field is required when email is not present.",
    //   ]);
    // } else {
    //   setPhoneError([]);
    // }

    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length > 0 && !email.match(mailformat)) {
      setEmailError(["You have entered an invalid email address!"]);
    }
    // else if (email.length === 0 && phone.length === 0) {
    //   setEmailError([
    //     "The email field is required when phone number is not present.",
    //   ]);
    // }
    else {
      setEmailError([]);
    }

    if (password1.localeCompare(password2) === 0) {
      setConfirmPasswordError("");
      setPassword(password1);
    } else {
      setConfirmPasswordError("Two password not macth");
    }
    if (confirmPasswordError.length > 0 || emailError.length > 0) {
      return 0;
    } else {
      singUpUser();
    }
  };

  const getLocation = async (latitude, longitude) => {
    if (latitude.length !== 0 && longitude.length !== 0) {
      try {
        const response = await axios.get(
          `https://us1.locationiq.com/v1/reverse.php?key=pk.9ddc8d900420336eb5d33f437cf1bd42&lat=${latitude}&lon=${longitude}&format=json`
        );
        if (response.data.address.country === "United States of America") {
          setcityLocation(
            `${
              response.data.address.city ? response.data.address.city + "," : ""
            } ${response.data.address.state}`
          );
        } else {
          setcityLocation(
            `${
              response.data.address.city ? response.data.address.city + "," : ""
            } ${response.data.address.country}`
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const singUpUser = async () => {
    var deviceToken;
    if (localStorage.getItem("deviceToken")) {
      deviceToken = localStorage.getItem("deviceToken");
    } else {
      deviceToken = "Null";
    }
    setLoading(true);
    let data;
    let numericalAge = parseInt(age);
    if (phone.length === 0) {
      data = {
        name,
        password: password1,
        email,
        // age: numericalAge,
        // gender,
        latitude: location.coordinates.lat,
        longitude: location.coordinates.lng,
        location: cityLocation,
        device_token: deviceToken,
      };
    } else if (email.length === 0) {
      data = {
        name,
        password: password1,
        // age: numericalAge,
        // gender,
        phone_number: phone,
        latitude: location.coordinates.lat,
        longitude: location.coordinates.lng,
        location: cityLocation,
        device_token: deviceToken,
      };
    } else {
      data = {
        name,
        password: password1,
        email,
        // age: numericalAge,
        // gender,
        phone_number: phone,
        latitude: location.coordinates.lat,
        longitude: location.coordinates.lng,
        location: cityLocation,
        device_token: deviceToken,
      };
    }
    if (referralCode.length > 0) {
      data["referral_code"] = referralCode;
    }

    if (navigator.userAgent.match(/(Windows)/i)) {
      data["platform"] = "Windows";
    } else if (navigator.userAgent.match(/(Android)/i)) {
      data["platform"] = "Android";
    } else if (navigator.userAgent.match(/(Macintosh)/i)) {
      data["platform"] = "Macintosh";
    } else {
      data["platform"] = `${navigator.userAgent}`;
    }
    try {
      const response = await API.post("/user/sign_up", data);
      setResponse(response.data.message);
      setName("");
      setNameError(undefined);
      setPassword1("");
      setPassword2("");
      setPasswordError(undefined);
      setEmail("");
      setEmailError(undefined);
      setPhone("");
      setPhoneError(undefined);
      const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
      authCtx.login(
        response.data.data.user.token,
        response.data.data.user,
        expirationTime
      );
      history.replace("/congrstulstions");
    } catch (error) {
      const {
        name,
        password,
        email,
        phone_number,
        // age,
        // gender,
        device_token,
        referral_code,
      } = error.response.data.errors;
      setNameError(name);
      setPasswordError(password);
      // if (email && email[0] === "The email has already been taken.") {
      // }
      setEmailError(email);
      setPhoneError(phone_number);
      setAgeError(age);
      setGenderError(gender);
      setReferralCodeError(referral_code);
      setLoading(false);
    }
  };

  return (
    <Container className="h-100">
      <Row className="justify-content-center h-100">
        <Col
          xs={12}
          md={8}
          lg={5}
          className="d-flex flex-column justify-content-center"
        >
          <div
            className="sign_in_sec"
            style={{ marginTop: "13.5vh", marginBottom: "7vh" }}
          >
            <Form onSubmit={handleSubmit}>
              <h2>Create account</h2>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Row>
                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type="text"
                          name="name"
                          placeholder="Full name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <AiOutlineUser style={style} />
                        {nameError &&
                          nameError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="col-12">
                      <div className="sn-field">
                        <select
                          className="form-control"
                          onChange={(e) => setGender(e.target.value)}
                          defaultValue={"DEFAULT"}
                        >
                          <option
                            value="DEFAULT"
                            disabled
                            style={{ color: "#caeee6" }}
                          >
                            Select gender
                          </option>
                          <option value="0">Male</option>
                          <option value="1">Female</option>
                          <option value="2">Unspecified</option>
                        </select>
                        <FaTransgender style={style} />
                        {genderError &&
                          genderError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type="text"
                          name="age"
                          placeholder="Age"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                        />
                        <AiOutlineCalendar style={style} />
                        {ageError &&
                          ageError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group> */}
                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <AiOutlineMail style={style} />
                        {emailError &&
                          emailError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type="text"
                          name="phone_number"
                          placeholder="Phone (optional if entered email)"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <AiOutlinePhone style={style} />
                        {phoneError &&
                          phoneError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group>

                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type={passwordShown1 ? "text" : "password"}
                          min="8"
                          name="password"
                          placeholder="Password"
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                        />
                        {passwordShown1 ? (
                          <FaEye
                            className="eye"
                            style={style}
                            onClick={togglePasswordVisiblity1}
                          />
                        ) : (
                          <FaEyeSlash
                            className="eye"
                            style={style}
                            onClick={togglePasswordVisiblity1}
                          />
                        )}
                        <AiOutlineLock style={style} />
                        {passwordError &&
                          passwordError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type={passwordShown2 ? "text" : "password"}
                          min="8"
                          name="confirmpassword"
                          placeholder="Confirm password"
                          value={password2}
                          onChange={(e) => setPassword2(e.target.value)}
                        />
                        {passwordShown2 ? (
                          <FaEye
                            className="eye"
                            style={style}
                            onClick={togglePasswordVisiblity2}
                          />
                        ) : (
                          <FaEyeSlash
                            className="eye"
                            style={style}
                            onClick={togglePasswordVisiblity2}
                          />
                        )}
                        <AiOutlineLock style={style} />
                        {confirmPasswordError && (
                          <small className="text-danger d-block">
                            {confirmPasswordError}
                          </small>
                        )}
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="col-12">
                      <div className="sn-field">
                        <Form.Control
                          size="lg"
                          type="text"
                          name="referralCode"
                          placeholder="Referral Code"
                          value={referralCode}
                          onChange={(e) => setReferralCode(e.target.value)}
                        />
                        <AiFillGift style={style} />
                        {referralCodeError &&
                          referralCodeError.map((error, index) => (
                            <small className="text-danger d-block" key={index}>
                              {error}
                            </small>
                          ))}
                      </div>
                    </Form.Group> */}
                    <Form.Group align="center" className="col-12">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfPQ_UdAAAAAK9rtBff3wJ2hSznnfE5QJ37rx_8"
                      />
                      {notRobotError && (
                        <small className="text-danger d-block">
                          {notRobotError}
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="col-12 text-center">
                      <Button size="lg" type="submit" className="px-5">
                        Get started
                      </Button>
                      <small className="d-block athor-option mt-2">
                        Already have an account ?{" "}
                        <Link to="/signin">Log in</Link>
                      </small>
                    </Form.Group>
                    <Form.Group className="col-12 text-center">
                      <small className="d-block athor-option mt-2">
                        Signing up implies that you agree to
                        <br />
                        <a
                          href="https://nattersearch.com/Terms&Conditions.html"
                          target={"_blank"}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Terms and Conditions
                        </a>
                        &nbsp;&&nbsp;
                        <a
                          href="https://nattersearch.com/PrivacyPolicy.html"
                          target={"_blank"}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Privacy policy
                        </a>
                        .
                      </small>
                    </Form.Group>
                  </Row>

                  {/* <Form.Group>
                  <div className="checky-sec st2">
                    <div className="fgt-sec">
                      <input type="checkbox" name="cc" id="c2" />
                      <label htmlFor="c2">
                        <span></span>
                      </label>
                      <small>
                        Yes, I understand and agree to the NatterSearch Terms &amp;
                        Conditions.
                      </small>
                    </div>
                  </div>
                </Form.Group> */}
                </>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SingUpForm;
