import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacypolicy">
      <Row className="justify-content-center">
        <Col md={10}>
          <h4 className="text-center mb-4">privacy policy</h4>
          <p className="mb-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into
          </p>
          <p className="mb-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into
          </p>
          <h5>terms</h5>
          <ul className="pl-3">
            <li className="mb-4">
              Lorem Ipsum is simply dummy text of the printing{" "}
            </li>
            <li className="mb-4">
              typesetting industry. Lorem Ipsum has been the
            </li>
            <li className="mb-4">standard dummy text ever since the 1500s</li>
            <li className="mb-4">unknown printer took a galley of type and</li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default PrivacyPolicy;
