import React from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Controls from "./Controls";
import "./Video.scss";

export default function Video(props) {
  const { users, track, setStart, setInCall, childRef, childFunc } = props;
  const [userInfo, setUserInfo] = useState({
    name: localStorage.getItem("calling_name"),
    image: localStorage.getItem("calling_image"),
  });
  const [gridSpacing, setGridSpacing] = useState(12);

  useEffect(() => {
    setGridSpacing(Math.max(Math.floor(12 / (users.length + 1)), 4));
  }, [users, track]);

  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      }
      if (seconds === 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  // useEffect(() => {
  //   const myIntrval = setInterval(() => {
  //     childFunc.current();
  //   }, 6000);

  //   return () => {
  //     clearInterval(myIntrval);
  //   };
  // });

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, []);
  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };

  // window.addEventListener("beforeunload", (ev) => {
  //   ev.preventDefault();
  //   return (ev.returnValue = "Are you sure you want to close the call?");
  // });

  return (
    <Row className="h-100">
      {users.length > 0 && users[0].videoTrack ? (
        <Col xs={12}>
          <AgoraVideoPlayer
            videoTrack={users[0].videoTrack}
            key={users[0].uid}
            className="userVideo"
          />
        </Col>
      ) : (
        <Col>
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${userInfo.image})`,
            }}
          ></div>
          <div className="h-100 d-flex flex-column justify-content-center text-overlay">
            <div className="call-animation">
              <img
                className="img-circle"
                src={`${userInfo.image}`}
                alt=""
                width="135"
              />
            </div>
            <h4 className="mt-5 mb-2 text">{userInfo.name}</h4>
            <h5 className="text my-2">
              {" "}
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </h5>
            <div className="controlsButtons">
              <Controls
                track={track}
                setStart={setStart}
                setInCall={setInCall}
                childRef={childRef}
                childFunc={childFunc}
              />
            </div>
          </div>
        </Col>
      )}
      {/* <Col xs={12} md={gridSpacing}>
          <AgoraVideoPlayer videoTrack={tracks[1]} className="userVideo" />
        </Col> */}
      {/* {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <Col xs={12} md={gridSpacing}>
                  <AgoraVideoPlayer
                    videoTrack={user.videoTrack}
                    key={user.uid}
                    className="userVideo"
                  />
                </Col>
              );
            } else
              return (
                <Col xs={12} md={gridSpacing}>
                  <img
                    src="https://placeimg.com/600/600/people"
                    className="img-fluid"
                  />
                </Col>
              );
          })} */}
    </Row>
  );
}
