import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { TiMessages } from "react-icons/ti";
import { BiMailSend } from "react-icons/bi";
import API from "../../utils/DevApi";
import UserMessageCard from "../UserMessageCard/UserMessageCard";
import Loader from "../Loader/Loader";
import "./Messages.scss";

const Messages = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [key, setKey] = useState("ChatInbox");
  const [ChatInbox, setChatInbox] = useState([]);
  const [ChatSent, setChatSent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getChatInbox = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/chat/inbox", config);
      setChatInbox(response.data.data.chats);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  const getChatSent = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await API.get("/chat/sent", config);
      setChatSent(response.data.data.chats);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };
  useEffect(() => {
    getChatInbox();
    getChatSent();
  }, []);
  return (
    <Row className="messages">
      <Col className="main-ws-sec">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-1 mb-md-3"
        >
          <Tab
            eventKey="ChatInbox"
            title={
              <>
                <TiMessages className="thumbnail-image" size={25} />

                <small className="d-none d-md-inline-block">
                  Messages Inbox
                </small>
              </>
            }
            className="user-profile-ov"
          >
            {loading && <Loader />}
            {!loading && ChatInbox.length === 0 && (
              <h4 className="text-center text-dark">No received messages</h4>
            )}
            {ChatInbox &&
              ChatInbox.map((user) => {
                return <UserMessageCard key={user.id} user={user} />;
              })}
          </Tab>
          <Tab
            eventKey="notification"
            title={
              <>
                <BiMailSend className="thumbnail-image" size={25} />
                <small className="d-none d-md-inline-block">
                  Messages Sent
                </small>
              </>
            }
          >
            {loading && <Loader />}
            {!loading && ChatSent.length === 0 && (
              <h4 className="text-center text-dark">No sent messages</h4>
            )}
            {ChatSent &&
              ChatSent.map((user) => {
                return <UserMessageCard key={user.id} user={user} />;
              })}
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Messages;
