import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import "./TransactionsHistory.scss";

export default function TransactionsHistory() {
  const [TransactionsHistory, setTransactionsHistory] = useState(0);
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const fetchTransactionsHistory = async () => {
    const token = localStorage.getItem("token");
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("myTransactions", config);
      setTransactionsHistory(response.data.data.transactions);
      setLoading(false);
    } catch (error) {
      if (error.response.status === "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactionsHistory();
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  return (
    <div className="TransactionsHistory">
      <h3 className="mb-3">Transactions History</h3>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col xs={6}>
              {TransactionsHistory.map((transcations, index) => {
                return (
                  <Row key={index} className="historyCard">
                    <Col className="text-left">
                      <p className="mb-0">
                        {transcations.userName.length === 0
                          ? transcations.type
                          : `Call: ${transcations.userName}`}
                      </p>
                      <small>{transcations.createdAt.slice()}</small>
                    </Col>
                    <Col
                      className={`text-right ${
                        +transcations.amount > 0 ? "add" : "withdraw"
                      }`}
                    >
                      ${transcations.amount}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
