import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import API from "../../utils/API";

const ReactNotificationComponent = ({ title, body, data, handleClose }) => {
  let hideNotif = title === "";
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  //"End"

  useEffect(() => {
    if (!hideNotif) {
      if (data.type == "2") {
        history.replace("/incomingcall");
      } else if (data.status === "Accept") {
        setTimeout(() => {
          history.replace("/call");
        }, 1500);
      } else if (
        localStorage.getItem("calling_user_id") &&
        data.status !== "Reject"
      ) {
        history.replace("/rating");
      } else if (data.status === "End") {
        {
          localStorage.removeItem("calling_name");
          localStorage.removeItem("calling_image");
          history.replace("/");
        }
      } else {
        toast.success(<Display />, {
          toastId: Math.random().toString(36).slice(2),
          onClose: () => {
            handleClose();
            if (data.type == "3") {
              {
                if (data.status == "Reject") {
                  history.replace("/");
                }
              }
            } else if (data.type == "4") {
              history.replace("/wallet");
            }
          },
        });
      }
    }
  }, [title, body, data]);

  // if (!hideNotif) {
  //   toast.success(<Display />, {
  //     toastId: Math.random().toString(36).slice(2),
  //     onClose: () => {
  //       handleClose();
  //     },
  //   });
  // }

  function Display() {
    if (data.type == "1" || data.type == "4") {
      return (
        <div>
          <h4>{title}</h4>
          <p>{body}</p>
        </div>
      );
    } else if (data.type == "2") {
      return (
        <div>
          <h4>{title}</h4>
          <p>{body}</p>
        </div>
      );
    } else if (data.type == "3") {
      return (
        <div>
          <h4>{title}</h4>
          <p>
            {data.status == "Reject"
              ? "User rejected your call"
              : data.status == "Accept"
              ? "User accepted your call"
              : "User Ended your call"}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <Link className="text-white" to={`/userprofile/${data.user_id}`}>
            <h4>{title}</h4>
            <p>{body}</p>
          </Link>
        </div>
      );
    }
  }

  if (data.type == "2") {
    return (
      <ToastContainer
        autoClose={1000}
        hideProgressBar
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    );
  }
  if (data.type == "3") {
    return (
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    );
  } else {
    return (
      <ToastContainer
        limit={1}
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={true}
      />
    );
  }
};

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;
