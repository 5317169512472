import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HzHomeImage from "../helpers/images/hzhomeimage3.jpg";
import vHomeImage from "../helpers/images/vhomeimage3.jpg";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";
import "./Aboutus.scss";

const Aboutus = () => {
  // const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  // const paddingValue = iOS ? "17vh" : "";
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;
  return (
    <div
      className={`aboutUs ${aboutus ? "py-0" : ""}
        `}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="greenBackGround">
            <h2 className="py-2 mb-0 white">
              How to Share your Knowledge
            </h2>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col>
            <p>
              <br></br>
              <ul className="green">
                <li>Sign up using an email or phone number.</li>
                <li>
                  List your topics of expertise (up to 10 topics) and brag about
                  your years of knowledge and first-hand experiences.
                </li>
                <li>
                  Name your price in $/minute and set your status to “Available
                  to Natter.”
                </li>
                <li>
                  Users will find you in our database when they search a topic
                  you are knowledgeable in.
                </li>
                <li>
                  Receive in-app calls when you are available and share your
                  knowledge.
                </li>
                <li>
                  At the end of the call, you will receive 80% of the call
                  price.
                </li>
                <li>Users will rate your expertise after each voice call.</li>
                <li>
                  Accumulate $$$ and transfer to any PayPal account in seconds
                  for FREE or use your $$$ to call experts to learn about your
                  topic of interest.
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
