import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";
import "./IntroVideo.scss";

export default function IntroVideo() {
  return (
    <Container className="justify-content-center">
      <Row>
        <Col>
          <center>
            <h2 className="greenColor title mt-4">
              Someone is willing to pay for your knowledge
            </h2>
            <video src={Nattersearch} poster={NSVideo} controls width="85%" className='mb-3' />
            <p>
              Your mind holds unique experiences and knowledge others will not
              find when they search the internet. Knowledge from
              <br /> a degree you earned, a career path, a destination you
              visited, or even a hobby you enjoy.
              <br /> <b>It’s knowledge – Unique to YOU!</b>
            </p>
            <p>
              Earn money by simply sharing your knowledge with others. Every
              minute you spend on a voice call talking about what
              <br /> you know best will add $$$ to your wallet.{" "}
              <b>Be the expert others will natter with.</b>
            </p>
            <p>
              <b>
                Download the NatterSearch app, available on iOS and Android, or
                sign up on our website
                <br />
                Sign up in seconds, share your knowledge,{" "}
                <span className="greenColor">
                  and earn money while doing it!
                </span>
              </b>
            </p>
          </center>
        </Col>
      </Row>
    </Container>
  );
}
