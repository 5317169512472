import { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import reject from "../helpers/images/reject.png";
import accept from "../helpers/images/accept.png";
import ringtone from "../helpers/audio/RecievingCall.mp3";
import API from "../../utils/API";
import AuthContext from "../../store/auth-context";
import "./IncomingCalling.scss";

const IncomingCalling = ({ callingInfo }) => {
  const [image, setImage] = useState(callingInfo.user_image);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const audioElement = new Audio(ringtone);
  let userImg = image ? (image.length ? image : defaultImage) : defaultImage;

  const joinCall = async (id) => {
    if (loading) {
      return 0;
    }
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyParameters = {
        id: id,
        status: 1,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await API.post("update/call", bodyParameters, config);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }

    localStorage.setItem("room_id", callingInfo.id);
    localStorage.setItem("room_name", callingInfo.room_name);
    localStorage.setItem("room_token", callingInfo.room_token);
    localStorage.setItem("calling_name", callingInfo.user_name);
    localStorage.setItem("calling_image", userImg);
    setTimeout(() => {
      history.replace("/call");
    }, 1000);
  };

  const recjectCall = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const bodyParameters = {
        id: id,
        status: 0,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await API.post("update/call", bodyParameters, config);
      history.replace("/");
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace("/");
    }, 30000);
    audioElement.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioElement.play();
    return () => {
      audioElement.pause();
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container className="incomingcalling text-center h-100">
      <Row className="h-100">
        <Col>
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${userImg})`,
            }}
          ></div>
          <div className="h-100 d-flex flex-column justify-content-center text-overlay">
            <span className="my-5 text">Incoming Call</span>
            <div className="call-animation">
              <img className="img-circle" src={userImg} alt="" width="135" />
            </div>
            <h4 className="my-5 text">{callingInfo.user_name}</h4>
            <div className="answerButtons">
              <img
                src={reject}
                className="img-fluid"
                onClick={() => recjectCall(callingInfo.id)}
              />
              <img
                src={accept}
                className="img-fluid ml-3"
                onClick={() => joinCall(callingInfo.id)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IncomingCalling;
