import React, { useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaCamera } from "react-icons/fa";
import defaultImage from "../helpers/images/group5090.png";
import spinner from "../helpers/images/spinner.gif";
import AuthContext from "../../store/auth-context";
import API from "../../utils/API";
import "./Sidebar.scss";

const Sidebar = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const searchClass = window.location.pathname.match(/^\/$/) ? "active" : "";
  const myprofileClass = window.location.pathname.match(
    /^\/myprofile|\/editprofile/
  )
    ? "active"
    : "";
  const historyClass = window.location.pathname.match(/^\/history/)
    ? "active"
    : "";
  const privacypolicyClass = window.location.pathname.match(/^\/privacypolicy/)
    ? "active"
    : "";
  const walletClass = window.location.pathname.match(/^\/wallet/)
    ? "active"
    : "";
  const referralClass = window.location.pathname.match(/^\/referralcode/)
    ? "active"
    : "";
  const messagesClass = window.location.pathname.match(/^\/messages/)
    ? "active"
    : "";

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [response, setResponse] = useState("");
  let sideImg = user
    ? user.image.length
      ? user.image
      : defaultImage
    : defaultImage;
  // if (imgData !== null) {
  //   sideImg = imgData;
  // } else if (user) {
  //   if (user.image.legnth > 0) {
  //     sideImg = user.image;
  //   } else {
  //     sideImg = defaultImage;
  //   }
  // } else {
  //   sideImg = defaultImage;
  // }
  const onChangePicture = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0]) {
      setLoading(true);
      // console.log("picture: ", e.target.files);
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      let user = JSON.parse(localStorage.getItem("user"));

      if (
        user.email.includes("demo@user1.com") ||
        user.email.includes("demo@user2.com") ||
        user.email.includes("demo@user3.com")
      ) {
        setLoading(false);
        return 0;
      }
      updateImage(e.target.files[0]);
    }
  };
  const updateImage = async (img) => {
    let image = img;
    let data = new FormData();
    data.append("image", image);

    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.post("/user/profile/update", data, config);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      setResponse(response.data.data.message);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { image } = error.response.data.errors;
      alert(image);
    }
  };
  return (
    <>
      <Nav className="col-md-12 px-0 sidebar" to="/">
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          {/* <img
            className="img-fluid d-inline-block mb-4"
            src="images/user_image_with_black_background.png"
          />
          <br />
          {user ? user.name : ""} */}
          <div className="main-left-sidebar mt-0">
            <div className="user_profile">
              <div className="user-pro-img">
                {loading ? (
                  <img src={spinner} alt="" className="img-fluid" />
                ) : (
                  <img src={sideImg} alt="" className="img-fluid" />
                )}
                <div className="add-dp" id="OpenImgUpload">
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={onChangePicture}
                  />
                  <label htmlFor="file">
                    <FaCamera color="#fff" size={10} />
                  </label>
                </div>
              </div>
              <div className="user_pro_status px-3">
                <h6>{user ? user.name : ""}</h6>
              </div>
            </div>
          </div>
        </Nav.Item>
        <Nav.Item className={searchClass}>
          <Nav.Link as={Link} to="/">
            Search
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className={myprofileClass}>
          <Nav.Link as={Link} to="/myprofile">
            My profile
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className={messagesClass}>
          <Nav.Link as={Link} to="/messages">
            Messages
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className={walletClass}>
          <Nav.Link as={Link} to="/wallet">
            Wallet
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className={historyClass}>
          <Nav.Link as={Link} to="/history">
            Call History
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item className={referralClass}>
          <Nav.Link as={Link} to="/referralcode">
            Referral Code
          </Nav.Link>
        </Nav.Item> */}
        {/* <Nav.Item className={privacypolicyClass}>
          <Nav.Link as={Link} to="/privacypolicy">
            Privacy policy
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="#"
            onClick={() => {
              authCtx.logout();
              history.replace("/");
            }}
          >
            Logout
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};
export default Sidebar;
