import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import API from "../../utils/API";

const Wallet = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const history = useHistory();


  async function buyNS(e) {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        amount,
        web:1
      };
      const response = await API.post("create/payment", bodyParameters, config);
      // setIframeURL();
      // window.open(
      //   response.data.data.paymentUrl,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
      window.location.replace(response.data.data.paymentUrl);
      setLoading(false);
    } catch (errorTitle) {
      if (errorTitle.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
    }
  }

  useEffect(() => {
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  return (
    <Row className="justify-content-center">
      <Col xs={10}>
        <h3 className="mb-3 text-left">Add Money to wallet:</h3>
        <Form onSubmit={buyNS}>
          <Form.Control
            size="lg"
            type="number"
            name="username"
            placeholder="Amount in $"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="1"
          /><br/>
          <Button type="submit" size="lg" className="ml-4">
            Buy
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default Wallet;
