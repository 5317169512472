import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iosmobile from "../helpers/images/iosmobile.png";
import dowanloadapp from "../helpers/images/dowanloadapp.png";
import White_logo from "../helpers/images/White_logo.png";
import AboutusWeb from "../Aboutus/AboutusWeb";
import "./Ios.scss";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";

const Ios = () => {
  const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  const paddingValue = iOS ? "17vh" : "";
  return (
    <>
      <div className="Ios">
        <Container>
          <Row className="text-center">
            <Col xs={12}>
              <p></p>
              <img src={White_logo} width="70%" className="White_logo" />
              <p></p>

              <h4>Someone is willing to pay for your knowledge</h4>
              <video
                src={Nattersearch}
                poster={NSVideo}
                controls
                width="100%"
              />
            </Col>
            <Col xs={12}>
              <p></p>
              <br></br>
              <h2>Download Our App</h2>
            </Col>
            <Col xs={12}>
              <img src={iosmobile} className="img-fluid" width={140} />
            </Col>
            <Col xs={12}>
              <a href="https://apps.apple.com/us/app/nattersearch/id1606853295">
                <img src={dowanloadapp} width="50%" className="img-fluid" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <AboutusWeb />
    </>
  );
};

export default Ios;
