import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
// import smallLogo from "../helpers/images/component23–1.png";
import AuthContext from "../../store/auth-context";
import imgSrc from "../helpers/images/White_logo.png";
import "./MainNavigation.scss";

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  const contactus = window.location.pathname.match(/^\/contactus/)
    ? true
    : false;
  const greenBg = authCtx.isLoggedIn || iOS || contactus ? "greenBg" : "";
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;
  // const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Navbar
      className={`${greenBg} ${aboutus ? "d-none" : ""}
        `}
      fixed="top"
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={imgSrc} className="img-fluid" alt="Natter Search" />
        </Navbar.Brand>
        {!iOS && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse
              className="justify-content-end"
              id="responsive-navbar-nav"
            >
              {authCtx.isLoggedIn ? (
                <>
                  {/* <Nav className="mr-auto">
            <Nav.Link as={Link} to="/home">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/myfavourite">
              Favourite
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
          </Nav> */}

                  {/* <Nav className="ml-auto">
                <NavDropdown
                  title={
                    <div className="pull-left d-inline-block">
                      <img
                        className="thumbnail-image"
                        src="images/Path12586.png"
                        alt="user pic"
                        width={30}
                      />
                      {user ? user.name : ""}&nbsp;
                    </div>
                  }
                  className="active text-right"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Header>Available Status</NavDropdown.Header>
                  <NavDropdown.Divider />
                  <NavDropdown.ItemText>
                    <Form.Check
                      type="radio"
                      id="radio1"
                      name="availability"
                      label="Available"
                      defaultChecked={user ? user.available_call : ""}
                    />
                  </NavDropdown.ItemText>
                  <NavDropdown.ItemText>
                    <Form.Check
                      type="radio"
                      id="radio2"
                      name="availability"
                      label="Not Available"
                      defaultChecked={user ? !user.available_call : ""}
                    />
                  </NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={Link}
                    to={`/profile/${user ? user.id : ""}`}
                  >
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      localStorage.clear();
authCtx.logout();
window.location.reload();
                    }}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav> */}
                </>
              ) : (
                <div className="text-center">
                  <Link to="/signin" className="btn mr-3">
                    sign in
                  </Link>
                  <Link to="/signup" className="btn">
                    sign up
                  </Link>
                </div>
              )}
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default MainNavigation;
