import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DemoUser from "../DemoUser/DemoUser";
import "./Header.scss";
// import MainNavigation from "../MainNavigation/MainNavigation";
// import mainImage from "../helpers/images/component22–1.png";
// import bubbles from "../helpers/images/Component18–5.png";
// import lens from "../helpers/images/s.png";
// import Search from "../Search/Search";

const Header = () => {
  // const welcome = window.location.pathname.match(/^\/$/) ? true : false;
  // const home = window.location.pathname.match(/^\/home/) ? true : false;
  // const signIn = window.location.pathname.match(/^\/signin/) ? true : false;
  // const signUp = window.location.pathname.match(/^\/signup/) ? true : false;
  return (
    <header className="pt-4 pb-1">
      <Container className="header">
        <Row className="text-center h-100">
          <Col className="d-flex flex-column justify-content-center">
            <span>

              <Link to="/signup" className="btn btn-primary buttonHeader">
                GET STARTED
              </Link>
              <small className="d-block">
                Already have an account ? <Link to="/signin">Log in</Link>
                <br />
                <DemoUser />
              </small>
            </span>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
