import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import API from "../../utils/API";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setmessage] = useState(undefined);
  // const [messageSuccess, setmessageSuccess] = useState(undefined);
  // const [messageError, setmessageError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const style = { color: "#3c9b83" };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
    };
    try {
      const response = await API.post("/create/password", data);
      // setmessageSuccess(response.data.message);
      setmessage(response.data.message);
      setEmail("");
      setLoading(false);
      setTimeout(() => {
        history.replace("/signin");
      }, 2000);
    } catch (error) {
      setLoading(false);
      // setmessageError(error.response.data.message);
      setmessage(error.response.data.message);
      setTimeout(() => {
        history.replace("/signin");
      }, 2000);
    }
  };

  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center">
        <Col
          xs={12}
          md={8}
          lg={5}
          className="d-flex flex-column justify-content-center"
        >
          <div className="sign_in_sec">
            {message && <Alert variant="dark">{message}</Alert>}
            {/* {messageSuccess && (
              <Alert variant="success">{messageSuccess}</Alert>
            )}
            {messageError && <Alert variant="danger">{messageError}</Alert>} */}
            {/* <Row className="justify-content-center mb-3">
              <Col md={4} className="text-center">
                <img
                  src="images/user_image_with_black_background.png"
                  className="img-fluid"
                />
              </Col>
            </Row> */}
            <h2>Forget Password</h2>
            {loading ? (
              <Loader />
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <div className="sn-field">
                    <Form.Control
                      size="lg"
                      type="email"
                      name="username"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <AiOutlineUser style={style} />
                  </div>
                </Form.Group>
                <Form.Group className="col-12 text-center">
                  <Button type="submit" size="lg">
                    Reset password
                  </Button>
                </Form.Group>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
