import React, { useEffect, useState, useContext } from "react";
import ReactStars from "react-stars";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Row, Col, Button, Alert } from "react-bootstrap";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import "./Rating.scss";
const Rating = () => {
  const [ratingValue, setRatingValue] = useState(0);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const ratingChanged = (newRating) => {
    setRatingValue(newRating);
  };

  useEffect(() => {
    setUserId(localStorage.getItem("calling_user_id"));
    setUserName(localStorage.getItem("calling_name"));
    setImage(localStorage.getItem("calling_image"));
    return () => {
      localStorage.removeItem("calling_user_id");
      localStorage.removeItem("calling_name");
      localStorage.removeItem("calling_image");
    };
  }, []);

  let userImg = image ? (image.length ? image : defaultImage) : defaultImage;

  async function RateUser(id) {
    if (ratingValue == 0) {
      history.replace("/");
    }
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        rate_to: id,
        rate: ratingValue,
      };
      const response = await API.post("/rate/user", bodyParameters, config);
      setLoading(false);
      localStorage.removeItem("calling_user_id");
      localStorage.removeItem("calling_name");
      localStorage.removeItem("calling_image");
      setResponse(response.data.message);
      setLoading(false);
      setTimeout(() => {
        history.replace("/");
      }, 1500);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }
  return (
    <Row className="Rating">
      <Col xs={12} md={6}>
        {response && <Alert variant="success">{response}</Alert>}
        {loading ? (
          <Loader />
        ) : (
          response.length === 0 && (
            <>
              <h5 className="greenColor">RATE YOUR EXPERENCE WITH</h5>
              <h5 className="garyColor">
                <span className="userName">{userName}</span>
              </h5>
              <img
                className="rounded-circle img-fluid my-3"
                src={`${userImg}`}
                width="50%"
              />
              <ReactStars
                className="ReactStars"
                count={5}
                size={24}
                edit={true}
                half={false}
                onChange={ratingChanged}
                value={ratingValue}
                color1="#ced4da"
                color2="#3c9b83"
              />
              <p className="garyColor">Reviews are anonymous</p>
              <Button
                className="px-5"
                onClick={() => {
                  RateUser(userId);
                }}
              >
                Continue
              </Button>
            </>
          )
        )}
      </Col>
    </Row>
  );
};

export default Rating;
