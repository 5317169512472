import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../store/auth-context";
import ReactStars from "react-stars";
import AddExpert from "../AddExpert/AddExpert";
import Loader from "../Loader/Loader";
import ShowExpert from "../ShowExpert/ShowExpert";
import NotfiyMe from "../NotfiyMe/NotfiyMe";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { BiMessageDetail } from "react-icons/bi";
import Favourite from "../Favourite/Favourite";
import { Link, useParams, useHistory } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { GoLocation } from "react-icons/go";
import defaultImage from "../helpers/images/group5090.png";
import callImage from "../helpers/images/phone.png";
import star from "../helpers/images/group.png";
import helped from "../helpers/images/group50097.png";
import group5126 from "../helpers/images/group5126.png";
import API from "../../utils/DevApi";
import "./UserProfile.scss";
import SendMessage from "../SendMessage/SendMessage";

const UserProfile = () => {
  const { id } = useParams();
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const LocalUser = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [key, setKey] = useState("home");
  const handleClose = () => setShow(false);

  const [show, setShow] = useState(false);
  const [expert, setExpert] = useState(null);

  const handleShow = (expert) => {
    setExpert(expert);
    setShow(true);
  };

  const [showNotfiy, setShowNotfiy] = useState(false);
  const handleCloseNotfiy = () => setShowNotfiy(false);

  const handleShowNotfiy = (user) => {
    setExpert(user);
    setShowNotfiy(true);
  };

  const [showMessage, setShowMessage] = useState(false);
  const handleCloseMessage = () => setShowMessage(false);

  const handleShowMessage = (user) => {
    setExpert(user);
    setShowMessage(true);
  };

  async function fetchUser() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        id: id,
      };
      const response = await API.post("/show/user", bodyParameters, config);
      setUser(response.data.data.user);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        id: id,
      };
      const response = await API.post("/delete/expert", bodyParameters, config);
      fetchUser();
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  const startCall = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        receiver: id,
      };
      const response = await API.post("/create/call", bodyParameters, config);
      localStorage.setItem("calling_user_id", id);
      localStorage.setItem("roomId", response.data.data.call.id);
      localStorage.setItem("room_id", response.data.data.call.id);
      localStorage.setItem("room_name", response.data.data.call.room_name);
      localStorage.setItem("room_token", response.data.data.call.room_token);
      history.replace(`/calling/${user.id}`);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      // console.log(error.response);
      toast.error(
        <div>
          <p>{error.response.data.message}</p>
        </div>
      );
    }
  };

  let userImg = user
    ? user.image.length
      ? user.image
      : defaultImage
    : defaultImage;

  return loading ? (
    <Loader />
  ) : (
    <>
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      <Row className="justify-content-center">
        <Col md={5} lg={4} className="d-flex justify-content-center">
          <div className="main-left-sidebar">
            <div className="user_profile">
              <div className="user-pro-img">
                <img src={userImg} alt="" className="img-fluid" />{" "}
                {/* <span className="rattingSpan">
                  <img src={star} /> 4.3
                </span> */}
                <div className="add-dp">
                  <label onClick={() => handleShowMessage(user)}>
                    <BiMessageDetail color="#3c9b83" size={10} />
                  </label>
                </div>
              </div>
              <div className="user_pro_status px-3">
                <h6 className="mb-0">
                  {user ? (
                    <>
                      {user.name}&nbsp;
                      <Favourite
                        favourite_to={user.id}
                        IsFavourite={user.IsFavourite}
                      />
                    </>
                  ) : (
                    ""
                  )}{" "}
                </h6>
                <small>
                  {user ? (
                    user.location.length > 0 ? (
                      <>
                        <GoLocation className="mb-2" /> {user.location}
                        <br />
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </small>

                <ReactStars
                  className="ReactStars"
                  count={5}
                  size={16}
                  isHalf={true}
                  edit={false}
                  value={user ? parseFloat(user.AvgRate) : 0}
                  color1="#ced4da"
                  color2="#3c9b83"
                />
                <Row className="justify-content-center mb-2">
                  <Col xs={7} md={10} lg={7}>
                    <span className="callPrice">
                      {user ? `$${user.call_price}` : null}&nbsp;
                      <span style={{ whiteSpace: "nowrap" }}>
                        &nbsp;
                        {/* <img src={group5126} width="17.5%" /> &nbsp; */}
                        per minute
                      </span>
                    </span>
                  </Col>
                </Row>
                <small>
                  {/* {user ? (
                    user.online ? (
                      <>active &nbsp;</>
                    ) : (
                      <span className="offline">inactive&nbsp;</span>
                    )
                  ) : (
                    ""
                  )} */}

                  {user ? (
                    user.available_call ? (
                      <>
                        {/* <Link to={`/calling/${user.id}`}> */}
                        <img
                          src={callImage}
                          className="img-fluid"
                          width="33px"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            startCall(user.id);
                          }}
                        />
                        {/* </Link> */}
                      </>
                    ) : (
                      <Button
                        className="ml-2"
                        variant="primary"
                        size="sm"
                        onClick={() => handleShowNotfiy(user)}
                      >
                        Notify me when available to natter
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="main-ws-sec">
        <Col className="tab-content">
          <div className="user-profile-ov tab-pane active show">
            <h5 className="my-2 my-md-4">
              <strong>EXPERTISE</strong>
            </h5>
            <div className="lightBg">
              <div className="title-bar mb-4">
                <div className="title">Topics</div>
              </div>
              {user ? (
                user.experts.length > 0 ? (
                  <div className="experts">
                    {user.experts.map((expert) => (
                      <span
                        className="expertsSpan lightBg"
                        key={expert.id}
                        onClick={() => handleShow(expert)}
                        style={{ cursor: "pointer" }}
                      >
                        <p>{expert.topic_title}</p>
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">
                    <h5>User has not added any topics</h5>
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
            <div className=" helped mt-2 mt-md-5">
              <h5>
                <strong>HELPED</strong>
              </h5>
              <div className="lightBg text-center">
                <h4 className="helpedTitle">{user ? user.name : ""} helped</h4>
                <Row className="justify-content-center">
                  <Col className="text-right">
                    <h1 className="helpedNumber">
                      {user ? user.count_help : ""}
                    </h1>
                  </Col>
                  <Col className="text-left">
                    <img src={helped} className="img-fluid" width="60%" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ShowExpert
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        fetchUser={fetchUser}
        expert={expert}
      />
      <NotfiyMe
        show={showNotfiy}
        handleClose={handleCloseNotfiy}
        handleShow={handleShowNotfiy}
        user={user}
      />
      <SendMessage
        show={showMessage}
        handleClose={handleCloseMessage}
        handleShow={handleShowMessage}
        user={user}
      />
    </>
  );
};

export default UserProfile;
