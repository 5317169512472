import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import ReactStars from "react-stars";
import AddExpert from "../AddExpert/AddExpert";
import EditExpert from "../EditExpert/EditExpert";
import { Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import defaultImage from "../helpers/images/group5090.png";
import { GoLocation } from "react-icons/go";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { ImCross } from "react-icons/im";
import { BsPencil, BsPlusCircle } from "react-icons/bs";
import API from "../../utils/DevApi";
import MyFavourite from "../MyFavourite/MyFavourite";
import MyNotifications from "../MyNotifications/MyNotifications";
import group5123 from "../helpers/images/group5123.png";
import group51231 from "../helpers/images/group5123(1).png";
import bell from "../helpers/images/bell.png";
import bell2 from "../helpers/images/bell(2).png";
import group5041 from "../helpers/images/group5041.png";
import group50412 from "../helpers/images/group5041(2).png";
import group50097 from "../helpers/images/group50097.png";
import love from "../helpers/images/1.png";
import love2 from "../helpers/images/1(2).png";
import group5126 from "../helpers/images/group5126.png";
import "./MyProfile.scss";

const MyProfile = () => {
  // const { id } = useParams();
  const LocalUser = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [key, setKey] = useState("home");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const [expert, setExpert] = useState(null);

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleEditShow = (expert) => {
    setExpert(expert);
    setShowEdit(true);
  };

  async function fetchUser() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/user/profile", config);
      setUser(response.data.data.user);
      setLoading(true);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchUser();
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  const handleDelete = async (id) => {
    setLoading(false);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = {
        id: id,
      };
      const response = await API.post("/delete/expert", bodyParameters, config);
      fetchUser();
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };

  const [available, setAvailable] = useState(false);

  useEffect(() => {
    setAvailable(user.available_call);
  }, [user]);

  const changeNatter = async (checked) => {
    setAvailable(checked);
    let data = {
      available_call: checked,
    };
    const token = localStorage.getItem("token");
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.post("/user/profile/update", data, config);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { available_call } = error.response.data.errors;
      console.log(available_call);
    }
  };
  let userImg = user
    ? user.image.length
      ? user.image
      : defaultImage
    : defaultImage;

  return (
    <>
      <Row className="justify-content-center">
        <Col md={5} lg={4} className="d-flex justify-content-center">
          <div className="main-left-sidebar">
            <div className="user_profile">
              <div className="user-pro-img">
                <img src={userImg} className="img-fluid" alt="user" />{" "}
                {/* <span className="rattingSpan">
                  <img src="images/Group.png" /> 4.3
                </span> */}
                <div className="add-dp">
                  {/* <input type="file" id="file" /> */}
                  <label>
                    {/* <FaCamera /> */}
                    <Link to="/editprofile">
                      <BsPencil color="#3c9b83" size={10} />
                    </Link>
                  </label>
                </div>
              </div>
              <div className="user_pro_status px-3">
                <h6 className="mb-0">{user ? user.name : ""}</h6>
                <small>
                  {user ? (
                    user.location.length > 0 ? (
                      <>
                        <GoLocation className="mb-2" /> {user.location}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </small>
                {/* <small>
                  {user ? (user.active ? "online" : "offline") : ""}
                </small> */}
                <ReactStars
                  className="ReactStars"
                  count={5}
                  size={16}
                  isHalf={true}
                  edit={false}
                  value={user ? parseFloat(user.AvgRate) : 0}
                  color1="#ced4da"
                  color2="#3c9b83"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={9} md={6} lg={4}>
          <div className="lightBg mb-3">
            <Row className="justify-content-center mb-2 text-center">
              <Col xs={7} md={10} lg={7}>
                <span className="callPrice">
                  {user ? `$${user.call_price}` : null}&nbsp;
                  <span style={{ whiteSpace: "nowrap" }}>
                    &nbsp;
                    {/* <img src={group5126} width="17.5%" /> &nbsp; */}
                    per minute
                  </span>
                </span>
              </Col>
            </Row>
            <div className="title-bar">
              <div className="title">Available to Natter</div>
              <div className="buttons">
                {user && (
                  <div className="onoffswitch">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      id="myonoffswitch"
                      checked={available}
                      onChange={(e) => changeNatter(e.target.checked)}
                    />
                    <label
                      className="onoffswitch-label"
                      htmlFor="myonoffswitch"
                    >
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                )}
              </div>
              {/* {user ? setAvailable(user.available_call) : ""} */}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="main-ws-sec">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-1 mb-md-3"
          >
            <Tab
              eventKey="home"
              title={
                <>
                  <img
                    className="thumbnail-image unActiveImage"
                    src={group5123}
                    alt="home unactive"
                  />
                  <img
                    className="thumbnail-image activeImage"
                    src={group51231}
                    alt="home active"
                  />
                  <small className="d-none d-md-inline-block">Profile</small>
                </>
              }
              className="user-profile-ov"
            >
              <h5 className="mb-4">Expertise</h5>
              <div className="lightBg">
                <div className="title-bar mb-4">
                  <div className="title">Topics (up to 10 )</div>
                  <div className="buttons">
                    <Button
                      className={`addExpert ${
                        user ? user.experts.length === 10 && "disabled" : ""
                      }`}
                      onClick={handleShow}
                    >
                      Add <BsPlusCircle />
                    </Button>
                  </div>
                </div>
                {user && loading ? (
                  user.experts.length > 0 ? (
                    <div className="experts">
                      {user.experts.map((expert) => (
                        <span className="expertsSpan lightBg" key={expert.id}>
                          <p onClick={() => handleEditShow(expert)}>
                            {expert.topic_title}
                          </p>
                          <ImCross
                            onClick={() => handleDelete(expert.id)}
                            size={10}
                          />
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">
                      <h5>No topics has been added</h5>
                    </div>
                  )
                ) : (
                  <Loader />
                )}
              </div>
              <h5 className="mb-4 mt-2 mt-md-5">Helped</h5>
              <div className="text-center helped  lightBg ">
                <h4 className="helpedTitle">you helped</h4>
                <Row className="justify-content-center">
                  <Col className="text-right">
                    <h1 className="helpedNumber">
                      {user ? user.count_help : ""}
                    </h1>
                  </Col>
                  <Col className="text-left">
                    <img
                      src={group50097}
                      className="img-fluid"
                      width="60%"
                      alt="natter search"
                    />
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="notification"
              title={
                <>
                  <img
                    className="thumbnail-image unActiveImage"
                    src={bell}
                    alt="bell unactive"
                  />
                  <img
                    className="thumbnail-image activeImage"
                    src={bell2}
                    alt="bell active"
                  />
                  <small className="d-none d-md-inline-block">
                    Notifications
                  </small>
                </>
              }
            >
              <Row className="justify-content-center">
                <Col lg={8} md={10} xs={12} className="text-center">
                  <MyNotifications />
                </Col>
              </Row>
            </Tab>
            {/* <Tab
              eventKey="helps"
              title={
                <>
                  <img
                    className="thumbnail-image unActiveImage"
                    src={group5041}
                    alt="help unactive"
                  />
                  <img
                    className="thumbnail-image activeImage"
                    src={group50412}
                    alt="help active"
                  />
                  <small className="d-none d-md-inline-block">Helps</small>
                </>
              }
            >
              <div className="text-center helped">
                <h4 className="helpedTitle">you helped</h4>
                <Row className="justify-content-center">
                  <Col className="text-right">
                    <h1 className="helpedNumber">
                      {user ? user.count_help : ""}
                    </h1>
                  </Col>
                  <Col className="text-left">
                    <img
                      src={group50097}
                      className="img-fluid"
                      width="60%"
                      alt="natter search"
                    />
                  </Col>
                </Row>
              </div>
            </Tab> */}
            <Tab
              eventKey="favourite"
              title={
                <>
                  <img
                    className="thumbnail-image unActiveImage"
                    src={love}
                    alt="favourite unactive"
                  />
                  <img
                    className="thumbnail-image activeImage"
                    src={love2}
                    alt="favourite active"
                  />
                  <small className="d-none d-md-inline-block">Favorites</small>
                </>
              }
            >
              <MyFavourite />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <AddExpert
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        fetchUser={fetchUser}
      />
      <EditExpert
        show={showEdit}
        handleClose={handleEditClose}
        handleShow={handleEditShow}
        fetchUser={fetchUser}
        expert={expert}
      />
    </>
  );
};

export default MyProfile;
