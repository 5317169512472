import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import "./ContactUs.scss";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [response, setResponse] = useState("");
  const [browser, setBrowser] = useState("");
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const browserInfo = () => {
    // var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    setBrowser(
      "" +
        "Browser name  = " +
        browserName +
        " / " +
        "Full version  = " +
        fullVersion +
        " / " +
        "Major version = " +
        majorVersion +
        " / " +
        "navigator.appName = " +
        navigator.appName +
        " / " +
        "navigator.userAgent = " +
        navigator.userAgent +
        " / "
    );
  };

  async function fetchUser() {
    const token = localStorage.getItem("token");
    if (token == "null") {
      return 0;
    }
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/user/profile", config);
      setName(response.data.data.user.name);
      setEmail(response.data.data.user.email);
      setLoading(false);
    } catch (error) {
      // if (error.response.status == "401") {
      //   localStorage.clear();
      //   authCtx.logout();
      //   window.location.reload();
      //   history.replace("/");
      // }
      console.log(error);
    }
  }

  useEffect(() => {
    browserInfo();
    authCtx.isLoggedIn ? fetchUser() : setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // if (name.length === 0) {
    //   setNameError("The name field is required.");
    //   console.log("name");
    // }
    // if (email.length === 0) {
    //   setEmailError("The email field is required.");
    //   console.log("email");
    // }
    // if (message.length === 0) {
    //   setMessageError("The message field is required.");
    //   console.log("message");
    // }
    // if (
    //   nameError.length > 0 ||
    //   emailError.length > 0 ||
    //   messageError.length > 0
    // ) {
    //   setLoading(false);
    //   console.log("error");
    //   return 0;
    // } else {
    //   console.log("nerror");
    // }

    let data = {
      name,
      email,
      message: message + browser,
    };

    try {
      const response = await API.post("/contactus", data);
      setName("");
      setEmail("");
      setMessage("");
      setNameError("");
      setEmailError("");
      setMessageError("");
      setResponse(response.data.message);
      setLoading(false);
      setTimeout(() => {
        history.replace("/");
      }, 2000);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      const { name, email, message } = error.response.data.errors;
      setNameError(name);
      setEmailError(email);
      setMessageError(message);
      setLoading(false);
    }
  };

  return (
    <div className="contactUs">
      <Container>
        <Row>
          <Col>
            <h1 className="mb-3">Contact us</h1>
            {response && <Alert variant="success">{response}</Alert>}
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3" controlId="formBasicName">
                  <Col xs={12} md={9}>
                    <Form.Control
                      type="text"
                      placeholder="Your name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    {nameError && (
                      <small className="text-danger d-block">{nameError}</small>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formBasicEmail"
                >
                  <Col xs={12} md={9}>
                    <Form.Control
                      type="email"
                      placeholder="Your email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {emailError && (
                      <small className="text-danger d-block">
                        {emailError}
                      </small>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formBasicMessage"
                >
                  <Col xs={12}>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Your message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    {messageError && (
                      <small className="text-danger d-block">
                        {messageError}
                      </small>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col xs={12} className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className={`px-5 ${
                        name.trim().length > 3 &&
                        email.trim().length > 9 &&
                        message.trim().length > 10
                          ? ""
                          : "disabled"
                      }`}
                      size="lg"
                    >
                      Submit
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ContactUs;
