import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import API from "../../utils/API";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import "./Favourite.scss";

const Favourite = ({ favourite_to, IsFavourite }) => {
  const [favourited, setFavourited] = useState(false);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    setFavourited(IsFavourite);
  }, []);

  const handleFavourite = () => {
    setFavourited(!favourited);
    addRemoveFavourite(favourite_to, token);
  };

  const addRemoveFavourite = async (id, token) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        favourite_to: id,
      };
      const response = await API.post(
        "/favourite/user",
        bodyParameters,
        config
      );
      // setFavourited(
      //   response.data.message.localeCompare("Favorite added") === 0
      //     ? true
      //     : false
      // );
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      alert(error.data.message);
      setFavourited(!favourited);
    }
  };

  return (
    <span className="favouriteIcon">
      {favourited ? (
        <AiFillHeart
          color={"#3c9b83"}
          size={30}
          onClick={() => handleFavourite()}
        />
      ) : (
        <AiOutlineHeart
          color={"#3c9b83"}
          size={30}
          onClick={() => handleFavourite()}
        />
      )}
    </span>
  );
};

export default Favourite;
