import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import "./Footer.scss";

const Footer = () => {
  const authCtx = useContext(AuthContext);
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;
  return (
    <footer
      className={`mt-auto  ${aboutus ? "d-none py-0" : ""}
        `}
    >
      <div className="firstSection">
        <Container>
          <Row>
            <Col>
              <span className="d-none d-md-block">
                © {new Date().getFullYear()} NatterSearch Corp, All
                Rights Reserved.
              </span>
            </Col>
            <Col className="text-center">
              {/* {authCtx.isLoggedIn && <Link to="/contactus">contact us</Link>} */}
              <Link to="/contactus">Contact us</Link>
            </Col>
            <Col className="text-right">
              <a
                href="https://www.linkedin.com/company/nattersearch"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
              <a href="https://twitter.com/nattersearch" target="_blank">
                <AiOutlineTwitter className="mx-2" />
              </a>
              <a href="https://www.facebook.com/nattersearch" target="_blank">
                <FaFacebookF />
              </a>
              <a
                className="ml-2"
                href="https://www.instagram.com/nattersearch/"
                target="_blank"
              >
                <RiInstagramFill />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
