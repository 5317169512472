import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import VideoCall from "./VideoCall";

function App() {
  const [inCall, setInCall] = useState(true);

  return (
    <>
      {inCall ? (
        <VideoCall setInCall={setInCall} />
      ) : (
        <Button variant="primary" onClick={() => setInCall(true)}>
          Join Call
        </Button>
      )}
    </>
  );
}

export default App;
