import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import { Row, Col } from "react-bootstrap";
import { GoLocation } from "react-icons/go";
import Favourite from "../Favourite/Favourite";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import axios from "axios";
import group5126 from "../helpers/images/group5126.png";
import "./UserMessageCard.scss";

const UserMessageCard = ({ user }) => {
  const { id, user_id, user_name, user_image, message, created_at } = user;
  const date = created_at.split(" ")[0].split("-");

  let userImg = user_image.length ? user_image : defaultImage;

  return (
    <Row className="profile-page justify-content-center">
      <Col xs={12} md={10}>
        <div
          className="card profile-header mt-0 mb-2"
          style={{ background: "#FFF" }}
        >
          <div className="body">
            {/* target="_blank" */}
            <Row>
              <Link to={`/userprofile/${user_id}`} className="col-12 d-flex">
                <Col xs={3} md={2} className="px-0 ">
                  <div className="profile-image text-center">
                    {" "}
                    <img src={userImg} alt="" className="img-fluid" />{" "}
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-center pr-0 pr-md-3 text-left">
                  <h5>
                    <strong>{user_name}</strong>
                    <small>{`${date[2]} - ${date[1]} - ${date[0]}`}</small>
                  </h5>
                  <h6>{message}</h6>
                </Col>
              </Link>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserMessageCard;
