import React, { useContext, Fragment } from "react";
import Footer from "../Footer/Footer";
import MainNavigation from "../MainNavigation/MainNavigation";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import AuthContext from "../../store/auth-context";
import "./Layout.scss";

const Layout = (props) => {
  const signIn = window.location.pathname.match(
    /(?:\b|')(signin|resetpassword)(?:\b|')/
  )
    ? true
    : false;
  const signUp = window.location.pathname.match(/^\/signup/) ? true : false;
  const authCtx = useContext(AuthContext);
  const backGround = signIn ? "singInBg" : signUp ? "singUpBg h-100" : "";
  const contactus = window.location.pathname.match(/^\/contactus/)
    ? true
    : false;
  const pages = window.location.pathname.match(
    /(?:\b|')(|signin|signup|resetpassword|contactus|aboutus|congrstulstions|myprofile|editprofile|history|privacypolicy|wallet|referralcode|userprofile|calling|incomingcall|call|rating|contactus)(?:\b|')/
  )
    ? true
    : false;

  const correctPage = pages || window.location.pathname === "/";
  return (
    <Fragment>
      {/* <ScrollButton /> */}
      <div
        className={`${backGround} ${
          !authCtx.isLoggedIn ? "d-flex flex-column" : "grayBg"
        }
          ${contactus ? "grayBg" : ""}
        `}
      >
        <main className="h-100">
          <MainNavigation />
          {correctPage ? props.children : <NotFoundPage />}
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;
