import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import API from "../../utils/API";
import "./ShowExpert.scss";

const ShowExpert = ({ show, handleClose, fetchUser, expert }) => {
  const [titleid, setTitleId] = useState(null);
  const [title, setTitle] = useState("");
  const [years, setYears] = useState("");
  const [des, setDescription] = useState("");

  useEffect(() => {
    expert && setTitleId(expert.id);
    expert && setTitle(expert.topic_title);
    expert && setYears(expert.years_exp);
    expert && setDescription(expert.description);
  }, [expert]);

  return (
    <Modal
      className="ShowExpert"
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Form>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="topic_title">
            <Form.Label>Topic:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              autoComplete="off"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="years_exp">
            <Form.Label>Years of experience:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter years of experience"
              value={years}
              autoComplete="off"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control as="textarea" rows={3} value={des} disabled />
          </Form.Group>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ShowExpert;
