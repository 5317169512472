import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Component202 from "../helpers/images/Component202.png";
import "./Congrstulstions.scss";
const Congrstulstions = () => {
  return (
    <Container className="h-100">
      <Row className="justify-content-center successes-singup">
        <Col md={10} lg={8} className="text-center">
          <img src={Component202} className="img-fluid" alt="Congrstulstions" />
          <h2>Congrstulstions !</h2>
          <p>Your account has been created successfully</p>
          <br />
          <Link to="/" className="btn btn-primary btn-lg mb-3 px-5">
            continue
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Congrstulstions;
