import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import API from "../../utils/API";
import AuthContext from "../../store/auth-context";
import user_image_with_black_background from "../helpers/images/user_image_with_black_background.png";
import "./SingForm.scss";
const SingInForm = () => {
  const [username, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState(undefined);
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordError, setPasswordError] = useState(undefined);
  const [message, setmessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  // const [response, setResponse] = useState("");
  const style = { color: "#3c9b83" };
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var deviceToken;
    if (localStorage.getItem("deviceToken")) {
      deviceToken = localStorage.getItem("deviceToken");
    } else {
      deviceToken = "Null";
    }
    const data = {
      username,
      password,
      device_token: deviceToken,
    };
    if (navigator.userAgent.match(/(Windows)/i)) {
      data["platform"] = "Windows";
    } else if (navigator.userAgent.match(/(Android)/i)) {
      data["platform"] = "Android";
    } else if (navigator.userAgent.match(/(Macintosh)/i)) {
      data["platform"] = "Macintosh";
    } else {
      data["platform"] = `${navigator.userAgent}`;
    }
    try {
      const response = await API.post("/user/login", data);
      let expirationTime;
      checked
        ? (expirationTime = new Date(new Date().getTime() + 31536000 * 1000))
        : (expirationTime = new Date(new Date().getTime() + 3600 * 1000));
      authCtx.login(
        response.data.data.user.token,
        response.data.data.user,
        expirationTime
      );
      history.replace("/");
    } catch (error) {
      setLoading(false);
      if (error.response.data.errors) {
        const { username, password, device_token } = error.response.data.errors;
        setUserNameError(username);
        setPasswordError(password);
      } else {
        setmessage(error.response.data.message);
        setUserNameError(undefined);
        setPasswordError(undefined);
      }
    }
  };
  function toggle(value) {
    return !value;
  }

  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center">
        <Col
          xs={12}
          md={8}
          lg={5}
          className="d-flex flex-column justify-content-center"
        >
          <div className="sign_in_sec">
            {message && <Alert variant="danger">{message}</Alert>}
            <Row className="justify-content-center mb-3">
              <Col md={4} className="text-center">
                <img
                  src={user_image_with_black_background}
                  className="img-fluid"
                />
              </Col>
            </Row>
            <h2>Welcome Back!</h2>
            {loading ? (
              <Loader />
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <div className="sn-field">
                    <Form.Control
                      size="lg"
                      type="text"
                      name="username"
                      placeholder="Email or Phone Number"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <AiOutlineUser style={style} />
                    {userNameError &&
                      userNameError.map((error, index) => (
                        <small
                          className="text-danger mt-2 mt-md-1 d-block"
                          key={index}
                        >
                          {error}
                        </small>
                      ))}
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="sn-field">
                    <Form.Control
                      size="lg"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordShown ? (
                      <FaEye
                        className="eye"
                        style={style}
                        onClick={togglePasswordVisiblity}
                      />
                    ) : (
                      <FaEyeSlash
                        className="eye"
                        style={style}
                        onClick={togglePasswordVisiblity}
                      />
                    )}
                    <AiOutlineLock style={style} />
                    {passwordError &&
                      passwordError.map((error, index) => (
                        <small
                          className="text-danger mt-2 mt-md-1 d-block"
                          key={index}
                        >
                          {error}
                        </small>
                      ))}
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="checky-sec">
                    <div className="fgt-sec">
                      <input
                        type="checkbox"
                        name="cc"
                        id="c1"
                        checked={checked}
                        onChange={() => setChecked(toggle)}
                      />
                      <label htmlFor="c1">
                        <span></span>
                      </label>
                      <small>Remember me</small>
                    </div>
                    <Link to="/resetpassword">Forgot Password?</Link>
                  </div>
                </Form.Group>
                <Form.Group className="col-12 text-center">
                  <Button type="submit" size="lg">
                    LOGIN
                  </Button>
                </Form.Group>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SingInForm;
