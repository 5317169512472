import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Row, Col } from "react-bootstrap";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import "./MyNotifications.scss";
import notificationImg from "../helpers/images/notification.png";
import group5125 from "../helpers/images/group5125.png";
const MyNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setloading] = useState(true);
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  async function fetchNotifications() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("/my/notifications", config);
      setNotifications(response.data.data.notifications);
      setloading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);
  const renderNotifications = (notifications) => {
    const notificationsList = notifications.map((notification) => {
      if (notification.data.user_id) {
        return (
          <div className="card notification" key={notification.id}>
            <div className="body">
              <Row>
                <Link
                  to={`userprofile/${notification.data.user_id}`}
                  className="col-12 d-flex"
                >
                  <Col xs={3} md={1} className="px-0 ">
                    <img
                      src={group5125}
                      className="img-fluid"
                      alt="Natter search logo"
                    />
                  </Col>
                  <Col className="d-flex flex-column justify-content-center text-left">
                    <h6>
                      <b>{notification.title}</b>
                    </h6>
                    <p className="mb-0">{notification.body}</p>
                  </Col>
                </Link>
              </Row>
            </div>
          </div>
        );
      } else {
        return (
          <div className="card notification" key={notification.id}>
            <div className="body">
              <Row>
                <Col className="d-flex" xs={12}>
                  <Col xs={3} md={1} className="px-0 ">
                    <img
                      src={group5125}
                      className="img-fluid"
                      alt="Natter search logo"
                    />
                  </Col>
                  <Col className="text-left">
                    <h6>
                      <b>{notification.title}</b>
                    </h6>
                    <p className="mb-0">{notification.body}</p>
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
        );
      }
    });
    return notificationsList;
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && notifications.length === 0 && (
        <>
          <Row className="justify-content-center">
            <Col xs={6} md={4}>
              <img
                src={notificationImg}
                className="img-fluid"
                alt="No notifications"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>No notifications</p>
            </Col>
          </Row>
        </>
      )}
      {!loading &&
        notifications.length > 0 &&
        renderNotifications(notifications)}
    </>
  );
};

export default MyNotifications;
