import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { getToken } from "../../firebaseInit";
import API from "../../utils/API";
import AuthContext from "../../store/auth-context";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [allow, setAllow] = useState(false);
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleConfirme = () => {
    setAllow(true);
    setShow(false);
  };

  const updateDeviceToken = async (deviceToken) => {
    const token = localStorage.getItem("token");
    const data = {
      device_token: deviceToken,
    };
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.post("/user/profile/update", data, config);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
    }
  };

  // console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        // console.log("Token is", data);
        !localStorage.getItem("deviceToken") &&
          localStorage.setItem("deviceToken", data) &&
          window.location.reload();

        authCtx.isLoggedIn && updateDeviceToken(data);
      }
      return data;
    }
    try {
      if (Notification.permission === "granted") {
        tokenFunc();
      } else if (Notification.permission === "default" && !allow) {
        handleShow();
      }
    } catch (error) {
      console.log(error);
    }
    allow && tokenFunc();
  }, [setTokenFound, allow]);

  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Body className="text-center">
        Allow notifications to get all features of the website
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Deny
        </Button>
        <Button variant="primary" onClick={handleConfirme}>
          Allow
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Notifications.propTypes = {};

export default Notifications;
