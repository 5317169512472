import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import gift from "../helpers/images/gift.svg";
import "./ReferralCode.scss";

const ReferralCode = () => {
  const [referralCodeValue, setReferralCodeValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  async function fetchReferralCode() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("myReferralCode", config);
      setReferralCodeValue(response.data.data.referralCode);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchReferralCode();
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);
  return (
    <div className="ReferralCode">
      {loading ? (
        <Loader />
      ) : (
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <h3 className="mb-3">My Rreferral Code</h3>
            <img src={gift} className="img-fluid" />
            <p className="my-4">
              Share your referral code to invite people to sign up and earn 10 NS
              coins for each user who signs up using your referral code.
            </p>
            <h1 className="mt-4">{referralCodeValue}</h1>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ReferralCode;
