import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/API";
import TransactionsHistory from "../TransactionsHistory/TransactionsHistory";
import AddMoney from "../AddMoney/AddMoney";
import SendMoney from "../SendMoney/SendMoney";
import "./Wallet.scss";

const Wallet = () => {
  const [walletValue, setWalletValue] = useState(0);
  const [view, setView] = useState("wallet");
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  async function fetchWallet() {
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("my_wallet", config);
      setWalletValue(response.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }
  useEffect(() => {
    fetchWallet();
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  const handleView = (view) => {
    switch (view) {
      case "History":
        return <TransactionsHistory />;

      case "AddMoney":
        return <AddMoney />;

      case "SendMoney":
        return <SendMoney />;

      default:
        return (
          <>
            <h3 className="mb-3">Available Balance</h3>
            <Row className="justify-content-center">
              <Col xs={12}>
                <h1>${walletValue.dollars_amount}</h1>
              </Col>
              <Col xs={4} className="d-flex flex-column mt-4">
                <Button onClick={() => setView("History")}>
                  Transactions History
                </Button>
                <Button className="my-3" onClick={() => setView("AddMoney")}>
                  Add Money from Paypal
                </Button>
                <Button onClick={() => setView("SendMoney")}>
                  Withdraw Money to Paypal
                </Button>
              </Col>
            </Row>
          </>
        );
    }
  };

  return (
    <div className="wallet">
      {view !== "wallet" && <FaChevronLeft onClick={() => setView("wallet")} />}
      {loading ? <Loader /> : <>{handleView(view)}</>}
    </div>
  );
};

export default Wallet;
