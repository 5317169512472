import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/API";

export default function SendMoney() {
  const LocalUser = JSON.parse(localStorage.getItem("user"));
  const [email, setEmail] = useState(LocalUser.email);
  const [emailError, setEmailError] = useState(undefined);
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = useState(undefined);
  const [message, setMessage] = useState({
    type: undefined,
    message: undefined,
  });
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  const withdrawMoney = async (e) => {
    e.preventDefault();
    setLoading(true);
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length === 0) {
      setEmailError("The email field is required.");
      return 0;
    } else if (!email.match(mailformat)) {
      setEmailError(["You have entered an invalid email address!"]);
      return 0;
    } else if (amount < 1) {
      setAmountError("Amount should be at least one dollar");
      return 0;
    }

    setEmailError(undefined);
    setAmountError(undefined);
    const token = localStorage.getItem("token");
    let data = {
      amount: amount,
      email: email,
    };

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.post("/transfer/money", data, config);
      console.log(response);
      setAmount("");
      setMessage({ type: "success", message: response.data.message });
      setLoading(false);
    } catch (error) {
      if (error.response.status === "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      setMessage({ type: "danger", message: error.response.data.message });
      setLoading(false);
    }
  };

  // const updateEmail = async () =>{
  //   const token = localStorage.getItem("token");
  //   let data ={
  //     email: email
  //   }
  //   try {
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` },
  //     };
  //     const response = await API.post("/user/profile/update", data, config);
  //     setEmailError(undefined);
  //     localStorage.setItem("user", JSON.stringify(response.data.data.user));
  //     withdrawMoney();
  //   } catch (error) {
  //     if (error.response.status == "401") {
  //       localStorage.clear();
  //       authCtx.logout();
  //       window.location.reload();
  //       history.replace("/");
  //     }
  //     const { email} =
  //       error.response.data.errors;
  //     setEmailError(email);
  //   }
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if(email === LocalUser.email) {
  //     await withdrawMoney();
  //   } else  {
  //     await updateEmail()
  //   }
  // }
  return (
    <div className="TransactionsHistory">
      <h3 className="mb-3">
        Enter the Paypal account to transfer money to and the amount in $
      </h3>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <Row>
            {message.message && (
              <Col xs={12}>
                <h6 className={`text-${message.type} d-block mb-3`}>
                  {message.message}
                </h6>
              </Col>
            )}
            <Col xs={12}>
              <Form onSubmit={withdrawMoney}>
                <Form.Control
                  size="lg"
                  type="email"
                  name="payPalEmail"
                  placeholder="PayPal Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <small className="text-danger d-block">{emailError}</small>
                )}
                <br />
                <Form.Control
                  size="lg"
                  type="number"
                  name="amount"
                  placeholder="Amount in $"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  min="1"
                  required
                />
                {amountError && (
                  <small className="text-danger d-block">{amountError}</small>
                )}
                <br />
                <Button type="submit" size="lg" className="ml-4">
                  Withdraw Money
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
