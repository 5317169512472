import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/Layout";
import Home from "../Home/Home";
import MyProfile from "../MyProfile/MyProfile";
import EditProfile from "../EditProfile/EditProfile";
import UserProfile from "../UserProfile/UserProfile";

import Header from "../Header/Header";
import SingInForm from "../SingInForm/SingInForm";
import SingUpForm from "../SingUpForm/SingUpForm";
import ResetPassword from "../ResetPassword/ResetPassword";
import AboutUs from "../Aboutus/Aboutus";
import AboutUsWeb from "../Aboutus/AboutusWeb";
import ContactUs from "../ContactUs/ContactUs";
import Congrstulstions from "../Congrstulstions/Congrstulstions";
import Search from "../Search/Search";
import History from "../History/History";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Wallet from "../Wallet/Wallet";
import ReferralCode from "../ReferralCode/ReferralCode";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

import AuthContext from "../../store/auth-context";

import { onMessageListener } from "../../firebaseInit";
import Notifications from "../Notifications/Notifications";
import ReactNotificationComponent from "../Notifications/ReactNotification";

import Agora from "../Agora/index";

import "./App.scss";
import Calling from "../Calling/Calling";
import IncomingCalling from "../IncomingCalling/IncomingCalling";
import Rating from "../Rating/Rating";
import Ios from "../Ios/Ios";
import Android from "../Android/Android";

import Dexie from "dexie";
import Messages from "../Messages/Messages";

import IntroVideo from "../IntroVideo/IntroVideo";

const App = () => {
  //set the database
  const db = new Dexie("calling_info");
  //create the database store
  db.version(1).stores({
    callingInfo: "++",
  });
  db.open().catch((err) => {
    console.log(err.stack || err);
  });
  const [inComingCall, setInComingCall] = useState("");
  useEffect(() => {
    //get all posts from the database
    const getPosts = async () => {
      let allPosts = await db.callingInfo.toArray();
      setCallingInfo(allPosts[0]);
      db.callingInfo.clear();
    };
    getPosts();
  }, []);
  const authCtx = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const iOS = navigator.userAgent.match(/(iPhone|iPod)/i) ? true : false;
  const android = navigator.userAgent.match(/(android)/i) ? true : false;
  const version = navigator.userAgent.match(/(Version)/i) ? true : false;
  const iPad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  const handleClose = () => {
    setShow(false);
    setNotification({
      title: "",
      body: "",
      data: "",
    });
  };
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    data: "",
  });

  const [callingInfo, setCallingInfo] = useState({});

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        data: payload.data,
      });

      if (payload.data.type === "2") {
        setCallingInfo(payload.data);
      }
    })
    .catch((err) => console.log("failed: ", err));
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;

  return (
    <>
      <BrowserRouter>
        <Switch>
          {iOS || iPad ? (
            <>
              <Route path="*">
                {aboutus ? <AboutUs /> : <Redirect to="/ios" />}
              </Route>
              <Route path="/ios">
                <Ios />
              </Route>
            </>
          ) : (
            ""
          )}
          <Route path="/android">
            <Android />
          </Route>
          <Layout>
            {show ? (
              <ReactNotificationComponent
                title={notification.title}
                body={notification.body}
                data={notification.data}
                handleClose={handleClose}
              />
            ) : (
              <></>
            )}
            <Notifications />
            <Route path="/" exact>
              {(iOS || iPad) && <Redirect to="/ios" />}
              {android && !version && <Redirect to="/android" />}
              {!authCtx.isLoggedIn ? (
                <>
                  <Header />
                  <IntroVideo />
                  <AboutUsWeb />
                </>
              ) : (
                <Home>
                  <Search />
                </Home>
              )}
            </Route>
            {!authCtx.isLoggedIn ? (
              <>
                <Route path="/signin">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <SingInForm />
                </Route>
                <Route path="/signup">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <SingUpForm />
                </Route>
                <Route path="/resetpassword">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <ResetPassword />
                </Route>
                <Route path="/contactus">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <ContactUs />
                </Route>
              </>
            ) : (
              <>
                <Route path="/congrstulstions">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Congrstulstions />
                </Route>

                <Route path="/myprofile">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <MyProfile />
                  </Home>
                </Route>
                <Route path="/editprofile">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <EditProfile />
                  </Home>
                </Route>
                <Route path="/messages">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <Messages />
                  </Home>
                </Route>
                <Route path="/history">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <History />
                  </Home>
                </Route>
                <Route path="/privacypolicy">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <PrivacyPolicy />
                  </Home>
                </Route>
                <Route path="/wallet">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <Wallet />
                  </Home>
                </Route>
                <Route path="/referralcode">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <ReferralCode />
                  </Home>
                </Route>
                <Route path="/userprofile/:id">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <UserProfile />
                  </Home>
                </Route>

                <Route path="/calling/:id">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <Calling />
                  </Home>
                </Route>
                <Route path="/incomingcall">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <IncomingCalling callingInfo={callingInfo} />
                  </Home>
                </Route>

                <Route path="/call">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <Agora />
                  </Home>
                </Route>
                <Route path="/rating">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <Home>
                    <Rating />
                  </Home>
                </Route>

                <Route path="/contactus">
                  {(iOS || iPad) && <Redirect to="/ios" />}
                  {android && !version && <Redirect to="/android" />}
                  <ContactUs />
                </Route>
              </>
            )}
            <Route path="/aboutus" exact={true}>
              <AboutUs />
            </Route>
            <Route path="/404">
              <NotFoundPage />
            </Route>
            {/* <Route path="*">
              <Redirect push to="/404" />
            </Route> */}
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
