import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useClient } from "./settings";
import { useHistory } from "react-router-dom";
import { FaMicrophoneAlt, FaMicrophoneAltSlash } from "react-icons/fa";
// import { IoVideocam, IoVideocamOff } from "react-icons/io5";
// import { MdExitToApp } from "react-icons/md";
// import { Container, Row, Col, Button } from "react-bootstrap";
import API from "../../utils/API";
import AuthContext from "../../store/auth-context";
import CloseIcon from "../helpers/images/end.png";
import MutedIcon from "../helpers/images/muted.png";
import UnMutedIcon from "../helpers/images/unmuted.png";

export default function Controls(props) {
  const history = useHistory();
  const client = useClient();
  const { track, setStart, setInCall, childRef, childFunc } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [agoraError, setAgoraError] = useState(false);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    childRef.current = leaveChannel;
    childFunc.current = checkChannel;
  });

  const mute = async (type) => {
    if (type === "audio") {
      await track.setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    }
  };
  const checkChannel = () => {
    if (agoraError) {
      checkChannelServer();
    } else {
      checkChannelAgora();
    }
  };

  const checkChannelAgora = async () => {
    try {
      const roomName = localStorage.getItem("room_name");
      const username = "484be73ad185405cb1e22da0ef299e05";
      const password = "f21f9452c8e84b7d802891e8e10f707b";
      const encodedBase64Token = Buffer.from(
        `${username}:${password}`
      ).toString("base64");
      const authorization = `Basic ${encodedBase64Token}`;

      const response = await axios.get(
        `https://api.agora.io/dev/v1/channel/user/5ee7425eb209418b80a4a3402724cac0/${roomName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authorization,
          },
        }
      );
      console.log(response);
      console.log(response.data.data.users.length === 1);
      
      if (response.data.data.users.length === 1) {
        kickChannel();
      }
    } catch (error) {
      // checkChannelServer();
      setAgoraError(true);
    }
  };

  const checkChannelServer = async () => {
    const roomName = localStorage.getItem("room_name");
    let data = {
      room_name: roomName,
    };
    try {
      const response = await API.post("/checkCall", data);
      console.log(response);
      if (response.data.data.users.length === 1) {
        kickChannel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const kickChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    track.close();
    setStart(false);
    setInCall(false);
    localStorage.removeItem("room_id");
    localStorage.removeItem("room_name");
    localStorage.removeItem("room_token");
    if (localStorage.getItem("calling_user_id")) {
      history.replace("/rating");
    } else {
      {
        localStorage.removeItem("calling_name");
        localStorage.removeItem("calling_image");
        history.replace("/");
      }
    }
  };

  // useEffect(() => {
  //   const myIntrval = setInterval(() => {
  //     checkChannel();
  //   }, 60000);

  //   return () => {
  //     clearInterval(myIntrval);
  //   };
  // });

  const endCall = () => {
    const token = localStorage.getItem("token");
    const roomId = localStorage.getItem("room_id");
    try {
      const bodyParameters = {
        id: roomId,
        status: 2,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      API.post("update/call", bodyParameters, config);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  };
  var leave = false;

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    track.close();
    setStart(false);
    setInCall(false);
    if (!leave) {
      leave = true;
      endCall();
    }
    localStorage.removeItem("room_id");
    localStorage.removeItem("room_name");
    localStorage.removeItem("room_token");
    if (localStorage.getItem("calling_user_id")) {
      history.replace("/rating");
    } else {
      {
        localStorage.removeItem("calling_name");
        localStorage.removeItem("calling_image");
        history.replace("/");
      }
    }
  };

  return (
    <>
      <img src={CloseIcon} onClick={() => leaveChannel()} />

      <span className="d-flex flex-column justify-content-center">
        {trackState.audio ? (
          <FaMicrophoneAlt
            onClick={() => mute("audio")}
            style={{ cursor: "pointer" }}
            size={30}
          />
        ) : (
          <FaMicrophoneAltSlash
            onClick={() => mute("audio")}
            style={{ cursor: "pointer" }}
            color={"#3c9b83"}
            size={30}
          />
        )}
      </span>
    </>
  );
}
